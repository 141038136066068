import React, { useRef, useEffect, Suspense, useState } from "react";
import * as d3 from "d3";
import { voronoiMapSimulation, voronoiMapInitialPositionRandom} from "d3-voronoi-map";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { metroDataSlice, industryState, selectedMetroState, industryPolygonMap } from "recoil/selectors";
import { populationState,viewState,industryPolygonsState, selectedIndustryDomState, selectedIndustryIDState } from "recoil/atoms";
import { foregroundColorFor } from "../../../utils/color";
import IndustryPopup from "./IndustryPopup";
import Loading from "../../../components/Loading";
import styled from "styled-components";

const StyledDiv = styled.div`
  transition: opacity 300ms linear;
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.active ? "auto" : "none")};
  opacity: ${(props) => (props.active ? "100" : "0")};
`;
const seedrandom = require("seedrandom");

const industryColor = (d) => {
  return d3.scaleSequential(d3.interpolateRdYlGn).domain([0, 100])(d.color);
};

function computeCirclingPolygon(radius) {
  var points = 60,
    increment = _2PI / points,
    circlingPolygon = [];

  for (var a = 0, i = 0; i < points; i++, a += increment) {
    circlingPolygon.push([
      radius + radius * Math.cos(a),
      radius + radius * Math.sin(a),
    ]);
  }

  return circlingPolygon;
}

const _2PI = 2 * Math.PI;
//end: constants

//begin: layout conf.

const svgWidth = 500,
  svgHeight = 500,
  // margin = { top: 10, right: 10, bottom: 10, left: 10 },
  height = svgHeight, // - margin.top - margin.bottom,
  width = svgWidth, // - margin.left - margin.right,
  halfWidth = width / 2,
  halfHeight = height / 2,
  treemapRadius = 250,
  treemapCenter = [halfWidth, halfHeight + 5];
//end: layout conf.

function makeSafeForCSS(name) {
  return name.replace(/[^a-z0-9]/g, function (s) {
    var c = s.charCodeAt(0);
    if (c === 32) return "-";
    if (c >= 65 && c <= 90) return "_" + s.toLowerCase();
    return "__" + ("000" + c.toString(16)).slice(-4);
  });
}

const Industries = ({ active }) => {
  const metro = useRecoilValue(selectedMetroState);
  const metroData = useRecoilValue(metroDataSlice);
  const population = useRecoilValue(populationState);
  const view = useRecoilValue(viewState);
  const data = useRecoilValue(industryState);
  const svgRef = useRef(null);
  const polygonMap = useRecoilValue(industryPolygonMap);
  const setLastPolygons = useSetRecoilState(industryPolygonsState);
  const [sim, setSim] = useState(null);
  const setIndustryDom = useSetRecoilState(selectedIndustryDomState);
  const setIndustryID = useSetRecoilState(selectedIndustryIDState);
  
  function handleOver(d, elem) {
    setIndustryID(d.site.originalObject.data.originalData.id);
    setIndustryDom(elem);
  }
  function handleOut(d, elem) {
    setIndustryID(null);
    setIndustryDom(null);
  }

  // update based on ui changes
  useEffect(() => {
    const currentData = data; 
    const svg = d3.select(svgRef.current);

    if (
      metro &&
      metroData &&
      svgRef.current &&
      currentData.length > 0
      // metro.geo_code > 0
      ) {
        svg.transition().duration(500).style("opacity", "1");
        if (sim) {
          sim.stop();
          const polygons = sim.state().polygons;
          setLastPolygons(polygons);
        }
  
        const simulation = voronoiMapSimulation(currentData);
        setSim(simulation);
  
        simulation
          .prng(seedrandom("socos"))
          // set the weight accessor
          .weight(function (d) {
            return d.weight;
          })
          // set the clipping polygon
          .clip(computeCirclingPolygon(treemapRadius))
          .initialPosition(function (d, i, arr, thesim) {
            return polygonMap[d.id]
              ? polygonMap[d.id].position
              : voronoiMapInitialPositionRandom()(d, i, arr, thesim);
          })
          .minWeightRatio(0.075)
          .maxIterationCount(250)
          .convergenceRatio(0.001)
          .on("tick", ticked)
          .on("end", function () {
            // retrieve the simulation's state, i.e. {ended, polygons, iterationCount, convergenceRatio}
            var state = simulation.state(), polygons = state.polygons;
  
            setLastPolygons(polygons);
          });
  
        function ticked() {
          try {
            var state = simulation.state(), // retrieve the simulation's state, i.e. {ended, polygons, iterationCount, convergenceRatio}
              polygons = state.polygons;
  
            var cells = svg
              .selectAll("path")
              .data(polygons, (d) => d.site.originalObject.data.originalData.id) // d3's join
              .join("path")
              .attr("id", (d) => makeSafeForCSS(d.site.originalObject.data.originalData.id))
              .classed("cell", true) // create cells at first render
              .attr("d", function (d) { return "M" + d.site.polygon.join(",") + "z";})
              .style("fill", function (d) { return industryColor(d.site.originalObject.data.originalData); });
  
            var labels = svg
              .selectAll(".label")
              .data( polygons, //.filter((d) => d.site.weight > 2000),
                (d) => d.site.originalObject.data.originalData.id + "-text")
              .join("text")
              .attr("class", "label")
              // .attr("transform", (d) => `translate(${d.site.x}, ${d.site.y)})`)
  
              .call(function (parent) {
                parent
                  .selectAll("tspan")
                  .data(function (d) {
                    const textLength = d.site.originalObject.data.originalData.id.length;
                    const textArray = d.site.originalObject.data.originalData.id.split(" ");
                    var i = 0;
                    var count = 0;
                    var newArray = [""];
                    while (count < textLength / 3) {
                      newArray[0] = newArray[0] + " " + textArray[i];
                      count = count + textArray[i].length;
                      i++;
                    }
                    newArray[0] = newArray[0].trim();
                    if (textArray.slice(i).length > 0) {
                      newArray[1] = textArray.slice(i).join(" ");
                    }
                    const numChars = Math.max(newArray[0].length, newArray[1] ? newArray[1].length : 0);
  
                    const bbox = d3.select(`#${makeSafeForCSS(d.site.originalObject.data.originalData.id)}`).node().getBBox();
  
                    let fontSize;
                    for (fontSize = 1; true; fontSize++) {
                      if (numChars * fontSize * 0.9 > bbox.width) {
                        break;
                      }
                    }
  
                    const color = foregroundColorFor(
                      industryColor(d.site.originalObject.data.originalData)
                    );
  
                    return newArray.map((t) => ({
                      data: d,
                      text: t,
                      numChars: numChars,
                      fontSize: fontSize,
                      bbox: bbox,
                      numLines: newArray.length,
                      color: color,
                    }));
                  })
                  .join("tspan")
                  .text(function (d) {
                    return d.text;
                  })
                  .style("font-size", function (d) {
                    return d.fontSize + "px";
                  })
                  .attr("x", (d) => d.data.site.x)
                  .attr(
                    "y",
                    (d, i) =>
                      d.data.site.y +
                      d.fontSize * 1.2 * i +
                      d.fontSize * 0.2 -
                      (d.numLines - 1) * d.fontSize * 0.6
                  )
                  .style("fill", (d) => d.color)
                  .attr("visibility", (d) =>
                    d.fontSize < 7 ? "hidden" : active={active} ? "visible" : "hidden"
                  );
                // .attr("dy", ".35em");
                // .attr("dx", function (d) {
                //   return `${-(this.getComputedTextLength() / 2)}px`;
                // });
              });
  
            // add hovers
            svg
              .selectAll("path")
              .on("mouseover", function (d) {
                handleOver(d, this);
              })
              .on("mouseout", function (d) {
                handleOut(d, this);
              });
            // setFinalState(polygons);
          } catch (error) {
            console.log("Error: ", error);
          }
        }
      } else {
        svg.transition().duration(500).style("opacity", "0");
      }
      return function () {};
    }, [population, view, metro, data]);
  
    useEffect(() => {
      return () => {
        sim && sim.stop();
        setSim(null);
        setLastPolygons(null);
      };
    }, []);

    return (
      <>
        <StyledDiv active={active}>
          <Suspense fallback={<Loading />}>
            <div className="industries-vis-container">
              <svg
                viewBox="0 0 500 500"
                preserveAspectRatio="xMinYMin meet"
                className="d3-component industries-vis"
                ref={svgRef}
              />
            </div>
            <IndustryPopup />
          </Suspense>
        </StyledDiv>
      </>
    );
  };

export default Industries;
