import React from "react";
import VisModeSelector from "./VisModeSelector";
import PopulationSelector from "./PopulationSelector";
import ViewSelector from "./ViewSelector";
import RegionLevelSelector from "./RegionLevelSelector";
import styled from "styled-components";

/*
 * This class pulls together the options for changing the data at the top of the Widget
 *
 * PopulationSelector: LGBTQ, Women or All
 * VisModeSelector: Map, Plot, Chart
 * ViewSelector: Means, Total
 * RegionLevelSelector: Metros, States (Countries later)
*/

const ButtonDiv = styled.div`
  display: inline;
  margin-left: 1%;
  margin-right: 1%;

`;

const VisOptions = () => {
  return (
    <div id={"vis-options"} >
      <div style={{display: "flex"}}>
          <ButtonDiv>
            <PopulationSelector /> 
          </ButtonDiv>
          <ButtonDiv>
            <VisModeSelector />
          </ButtonDiv>
          <ButtonDiv>
            <ViewSelector />
          </ButtonDiv>
        </div>
        <div>
          <ButtonDiv>
            <RegionLevelSelector />
          </ButtonDiv>
        </div>
      </div>
  );
};

export default VisOptions;
