import React, { Suspense } from "react";
import "App.css";
import { Grid, Header, Divider, Segment } from "semantic-ui-react";
import {} from "recoil/atoms";
import Vis from "sections/Vis";
import MetroInfo from "sections/MetroInfo";
import MetroData from "sections/MetroData";
import { RecoilRoot, useRecoilValue } from "recoil";
import Loading from "components/Loading";
import { createMedia } from "@artsy/fresnel";

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920,
  },
});

const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

function App({
  collection = "startout-v2",
  population = "LGBTQ",
  default_view = "Total",
}) {
  return (
    <RecoilRoot
      initializeState={({ set }) => {
        set({ key: "mockContextState" }, false);
        // set({ key: "populationState" }, population);
        set({ key: "deploymentContextState" }, collection);
        // set({ key: "viewState" }, default_view);
      }}
    >
      <style>{mediaStyles}</style>
      <MediaContextProvider>
        <Suspense fallback={<Loading />}>
          <div className="App index-container socos-styles">
            <Grid padded>
              <Grid.Column
                className={"left-side"}
                mobile={16}
                computer={5}
                color={"grey"}
                style={{
                  paddingLeft: "1%",
                  paddingRight: "1%",
                  paddingTop: "2rem",
                }}
                textAlign="left"
              >
                <div
                  style={{
                    fontSize: "2.1em",
                    fontWeight: "bold",
                    textAlign: "center",
                    lineHeight: "1.4em",
                    // color: "rgba(0, 156, 149)",
                    marginBottom: "1.5rem",
                  }}
                >
                  {"Inclusion Impact Index"}
                </div>
                <Divider inverted />
                <MetroInfo />
                <Segment basic as={Media} greaterThanOrEqual="computer">
                  <MetroData />
                </Segment>
              </Grid.Column>
              <Vis />
              <Segment basic as={Media} lessThan="computer">
                <Grid.Column
                  className="mobile-industry-vis"
                  width={16}
                  color={"grey"}
                  style={{}}
                >
                  <MetroData />
                </Grid.Column>
              </Segment>
            </Grid>
          </div>
        </Suspense>
      </MediaContextProvider>
    </RecoilRoot>
  );
}

export default App;
