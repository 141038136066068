import React from "react";
import { Grid } from "semantic-ui-react";
import { useRecoilValue, useRecoilState } from "recoil";
import { selectedMetroState } from "../../../recoil/selectors";
import { populationState, subPlotNonPopState } from "../../../recoil/atoms";
import styled from "styled-components";
import SubPlot from "./SubPlot";
import SubPlotPopup from "./SubPlotPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare as fasFaSquare } from "@fortawesome/free-solid-svg-icons";
import { faSquare as farFaSquare } from "@fortawesome/free-regular-svg-icons";
import InfoTip from "../../../components/InfoTip";

const StyledDiv = styled.div`
  transition: opacity 300ms linear;
  position: absolute;
  top: 0.5em;
  width: 100%;
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
  background-color:#FFFFFF;
  padding-top:15px;
`;

const Key = styled.div`
  border-bottom:solid 1px #A49494;
  padding-bottom:2%;
`;

const SpanLegendItem = styled.div`
  font-size: clamp(12px, 0.85vw, 12px);
  padding:0.5% 5%;
  font-weight:bold;
  display:flex;
`;

const SpanMissing = styled.div`
  background-color: rgb(255, 255, 255);
  font-size: clamp(11px, 1vw, 13px);
  width: 100%;
  padding: 3% 6% 5%;
  line-height:1.5em;
`;

function MetroInfo({ active }) {
  const metro = useRecoilValue(selectedMetroState);
  const population = useRecoilValue(populationState);
  const [nonPop, setNonPop] = useRecoilState(subPlotNonPopState);

  const handleNonPopClick = () => {
    setNonPop(!nonPop);
  };

  return (
    <>
      <StyledDiv active={active}>
      {metro.No_Statistical_Data === false ? 
        <><Key>
            <Grid>
              <Grid.Column width={8} style={{ paddingRight: "0px" }}>
                <InfoTip as="span" name="actualCounted">
                  <SpanLegendItem>
                    <FontAwesomeIcon icon={fasFaSquare} style={{ color: "#ad8961" }} />
                    &nbsp;&nbsp;&nbsp;
                    <span className="has-text-weight-bold info-actual-counted">
                      Reported
                    </span>
                  </SpanLegendItem>
                </InfoTip>
                {metro.population_text === "LGBTQ" ?
                  <><InfoTip as="span" name="actualEstimate">
                    <SpanLegendItem>
                      <FontAwesomeIcon icon={fasFaSquare} style={{ color: "rgb(13, 49, 107)" }} />
                      &nbsp;&nbsp;&nbsp;
                      <span className="has-text-weight-bold info-actual-estimate">
                        Reported + Estimated
                      </span>
                    </SpanLegendItem>
                  </InfoTip></>
                  : ''}
              </Grid.Column>
              <Grid.Column width={8}>
                <InfoTip as="span" name="achievable">
                  <SpanLegendItem>
                    <FontAwesomeIcon icon={fasFaSquare} style={{ color: "rgb(91, 55, 102)" }} />
                    &nbsp;&nbsp;&nbsp;
                    <span className="has-text-weight-bold info-achievable">
                      Achievable
                    </span>
                  </SpanLegendItem>
                </InfoTip>
                <InfoTip as="span" name="everyoneElse">
                  {population !== "All" && (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={handleNonPopClick}
                    >
                      <SpanLegendItem>
                        <FontAwesomeIcon icon={nonPop ? fasFaSquare : farFaSquare} style={{ color: "#39343b" }} />
                        &nbsp;&nbsp;&nbsp;
                        <span id="non-button-label" className="info-everyone-else">
                          Everyone Else (on/off)
                        </span>
                      </SpanLegendItem>
                    </div>
                  )}
                </InfoTip>
              </Grid.Column>
            </Grid>
          </Key><Grid celled="internally">
              <Grid.Row>
                <Grid.Column width={8}>
                  <SubPlot label={"Funding"} feature={"funding"} />
                </Grid.Column>
                <Grid.Column width={8}>
                  <SubPlot label={"Jobs"} feature={"jobs"} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <SubPlot label={"Patents"} feature={"patents"} />
                </Grid.Column>
                <Grid.Column width={8}>
                  <SubPlot label={"Exits"} feature={"exits_count"} />
                </Grid.Column>
              </Grid.Row>
            </Grid></>
        : <SpanMissing>
          This location does not have enough reported data to present chart information.
          <br /><br />
          {"\n\nTo submit information for this location to be considered for the dataset, email "}
          <a href="mailto:info@startout.org" style={{transition:"none"}}>info@startout.org</a>
        </SpanMissing>}
      </StyledDiv>
      <SubPlotPopup />
    </>
  );
}

export default MetroInfo;
