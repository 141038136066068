import React from "react";
import ReactDOM from "react-dom";
import "index.css";
import "./assets/css/semantic.css";
// import "./assets/css/font-awesome.min.css";
import App from "App";

// the widget code saves config params in window.mw.q
let props = {};
if (process.env.NODE_ENV === "production") {
  const q = window[window["PEII-Widget"]].q;
  props = { ...q[0][1], ...q[1][1] };
} else if (process.env.NODE_ENV === "development") {
  props = { collection: "sandbox" };
}

ReactDOM.render(
  <React.StrictMode>
    <App {...props} />
  </React.StrictMode>,
  document.getElementById("socos-index-container")
);
