import React from "react";
import { Popup } from "semantic-ui-react";
import { useRecoilState } from "recoil";
import {} from "recoil/selectors";
import { hoveredSubPlotDomState, hoveredSubPlotDataState } from "recoil/atoms";

const SubPlotPopup = () => {
  const [hoverDom, setHoveredDom] = useRecoilState(hoveredSubPlotDomState);
  const [hoverData, setHoverData] = useRecoilState(hoveredSubPlotDataState);

  return (
    <>
      {
        <Popup
          className="socos-subplot-popup"
          position="top center"
          context={hoverDom}
          open={hoverDom && hoverData}
          size="mini"
        >
          <Popup.Content
            className="socos-styles"
            style={{ textAlign: "center" }}
          >
            {hoverData && (
              <>
                <b>{hoverData.name}</b>
                <br />
                {hoverData.value}
              </>
            )}
          </Popup.Content>
        </Popup>
      }
    </>
  );
};

export default SubPlotPopup;
