import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { pristineState, plotYAxisState, visState, yLogScaleState } from "recoil/atoms";
import { plotFeaturesState } from "recoil/selectors";
import { Dropdown, Checkbox } from "semantic-ui-react";
import useDimension from "hooks/useDimension";

const YAxisSelector = ({ d3Ref }) => {
  const [feature, setFeature] = useRecoilState(plotYAxisState);
  const features = useRecoilValue(plotFeaturesState);
  const [pristine, setPristine] = useRecoilState(pristineState);
  const vis = useRecoilValue(visState);
  const { d3Height } = useDimension(d3Ref);
  const [logScale, setLogScale] = useRecoilState(yLogScaleState);

  const handleClick = (e, data) => {
    setFeature(data.value);
    setPristine(false);
  };

  const handleToggle = (event, data) => {
    setLogScale(!logScale);
  };

  return (
    <>

      <Dropdown
        id="y-axis-selector"
        className="mini"
        button
        options={features}
        defaultValue={feature}
        onChange={handleClick}
        style={{
          opacity: 1,
          display: "inline-block",
          marginLeft: "1vw",
          marginTop: "2em",
          boxShadow: "0px 0px 5px #a4a0a0",
          border: "solid 1px #CFCFCF"
        }} /><Checkbox
        toggle
        checked={logScale}
        onChange={handleToggle}
        label="Log Scale"
        style={{
          opacity: 1,
          display: "inline-block",
          marginLeft: "0.5vw",
          padding: "12px",
          boxShadow: "0px 0px 5px #a4a0a0",
          border: "solid 1px #CFCFCF",
          borderRadius: "8px",
          backgroundColor: "#e0e1e2",
          fontSize: "1rem"
        }} />
    </>
  );
};

export default YAxisSelector;
