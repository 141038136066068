import { useEffect } from "react";
import * as d3 from "d3";
import { useRecoilValue } from "recoil";
import { visState, populationState, viewState, chartFeatureState, chartLogScaleState, regionLevelState } from "recoil/atoms";
import { mainVisDimensionsState, metroDataSlice, selectedChartFeatureState, varKeys } from "recoil/selectors";
import format from "res/formats";

export const chartXScale = (metros, dimensions) => {
  const { margin, width } = dimensions;

  return d3
    .scaleBand()
    .domain(
      metros.map((m) => {
        return m.city;
      })
    )
    .range([margin.left, width - margin.right])
    .padding(1);
};

export const chartX = (i, metros, dimensions) => {
  return chartXScale(metros, dimensions)(i);
};

export const chartYScale = (
  metroData,
  population,
  view,
  chartfeatureID,
  chartFeatureKey,
  dimensions,
  logScale
) => {
  const { margin, height } = dimensions;
  const scale = logScale ? d3.scaleSymlog() : d3.scaleLinear();

  return (
    scale
      .domain([
        0,
        d3.max(metroData, (d) => {
          return d[chartFeatureKey];
        }),
      ])
      // .nice()
      .range([height - margin.bottom, margin.top])
  );
};

export const chartY = (
  d,
  metroData,
  population,
  view,
  chartfeatureID,
  chartFeatureKey,
  dimensions,
  logScale
) => {
  return chartYScale(
    metroData,
    population,
    view,
    chartfeatureID,
    chartFeatureKey,
    dimensions,
    logScale
  )(d[chartFeatureKey]);
};

export const chartYZero = (
  d,
  metroData,
  population,
  view,
  chartfeatureID,
  chartFeatureKey,
  dimensions,
  logScale
) =>
  chartYScale(
    metroData,
    population,
    view,
    chartfeatureID,
    chartFeatureKey,
    dimensions,
    logScale
  )(0);

export const chartHeight = (
  d,
  metroData,
  population,
  view,
  chartfeatureID,
  chartFeatureKey,
  dimensions,
  logScale
) => {
  return (
    chartYScale(
      metroData,
      population,
      view,
      chartfeatureID,
      chartFeatureKey,
      dimensions,
      logScale
    )(0) -
    chartYScale(
      metroData,
      population,
      view,
      chartfeatureID,
      chartFeatureKey,
      dimensions,
      logScale
    )(d[chartFeatureKey])
  );
};

export const chartWidth = (metroData, dimensions) => {
  return chartXScale(metroData, dimensions).bandwidth();
};

const Chart = ({ svgRef }) => {
  const dimensions = useRecoilValue(mainVisDimensionsState);
  const vis = useRecoilValue(visState);
  const allMetroData = useRecoilValue(metroDataSlice);
  const population = useRecoilValue(populationState);
  const view = useRecoilValue(viewState);
  const chartfeatureID = useRecoilValue(chartFeatureState);
  const chartFeature = useRecoilValue(selectedChartFeatureState);
  const logScale = useRecoilValue(chartLogScaleState);
  const chartFeatureKey = useRecoilValue(varKeys(chartfeatureID));
  const regionLevel = useRecoilValue(regionLevelState);

  // initialize and draw axes
  useEffect(() => {
    const metroData = allMetroData.filter((m) => m.level === regionLevel);
    //console.log("Initializing chart");
    const svg = d3.select(svgRef.current);

    // Add X axis

    var xAxisGroup = svg
      .append("g")
      .attr("class", "x axis chart-axis chart-x-axis")
      .attr(
        "transform",
        `translate(0,${dimensions.height - dimensions.margin.bottom})`
      )
      .style("opacity", 0);

    var xAxis = d3
      .axisBottom()
      .scale(chartXScale(metroData, dimensions))
      // .tickFormat((i) => metroData[i].city)
      .tickSizeOuter(0)
      .tickSize(0);

    xAxisGroup.call(xAxis);

    // Add Y axis
    var yAxisGroup = svg
      .append("g")
      .attr("class", "y axis chart-axis chart-y-axis")
      .attr("transform", "translate(" + dimensions.margin.left + ",0)")
      .style("opacity", 0);

    var yAxis = d3
      .axisLeft()
      .scale(
        chartYScale(
          metroData,
          population,
          view,
          chartfeatureID,
          chartFeatureKey,
          dimensions,
          logScale
        )
      )
      .tickFormat((n) => format(chartFeature.format, n, false));

    yAxisGroup.call(yAxis);

    // Add Lines
    svg
      .selectAll(".chart-line")
      .data(metroData, function (d, i) {
        return d.geo_code;
      })
      .enter()
      .append("line")
      .attr("class", (d) => `chart-line chart-line-${d.geo_code}`)
      .attr("x1", (d) => d.chart_line_x1)
      .attr("x2", (d) => d.chart_line_x2)
      .attr("y1", (d) => d.chart_line_y1)
      .attr("y2", (d) => d.chart_line_y2)
      .attr("stroke", (d) => d.chart_color)
      .attr("stroke-width", 1)
      .style("opacity", vis === "chart" ? 1 : 0);

    return () => {};
  }, []);

  // update scales and axese on options changes
  useEffect(() => {
    const metroData = allMetroData.filter((m) => m.level === regionLevel);
    /*console.log(
      "updating chart",
      vis,
      chartfeatureID,
      view,
      population,
      logScale,
      metroData[0].chart_line_x1,
      metroData[0].chart_line_x2,
      metroData[0].chart_line_y1,
      metroData[0].chart_line_y2
    );*/
    const svg = d3.select(svgRef.current);
    svg
      .selectAll(".chart-axis")
      .transition()
      .duration(1500)
      .style("opacity", vis === "chart" ? 1 : 0);

    // Update the Axis
    var xAxis = d3
      .axisBottom()
      .scale(chartXScale(metroData, dimensions))
      .tickSizeOuter(0)
      .tickSize(0);
    // .tickFormat((i) => metroData[i].city);

    var yAxis = d3
      .axisLeft()
      .scale(
        chartYScale(
          metroData,
          population,
          view,
          chartfeatureID,
          chartFeatureKey,
          dimensions,
          logScale
        )
      )
      .tickFormat((n) => format(chartFeature.format, n, false));

    svg
      .selectAll(".chart-y-axis")
      .transition()
      .duration(1500)
      .style("opacity", vis === "chart" ? 1 : 0)
      .call(yAxis);

    svg
      .selectAll(".chart-x-axis")
      .transition()
      .duration(1500)
      .style("opacity", vis === "chart" ? 1 : 0)
      .call(xAxis)
      .selectAll("text")
      .style("text-anchor", "start")
      .style("font-size", "12px")
      .attr("dx", "6px")
      .attr("dy", "0px")
      .attr("transform", "rotate(90)");

    // Update Lines
    const lineUpdate = svg
      .selectAll(".chart-line")
      .data(metroData, function (d, i) {
        return d.geo_code;
      });

    lineUpdate
      .enter()
      .append("line")
      .attr("class", (d) => `chart-line chart-line-${d.geo_code}`)
      .attr("x1", (d) => d.chart_line_x1)
      .attr("x2", (d) => d.chart_line_x2)
      .attr("y1", (d) => d.chart_line_y1 || 0)
      .attr("y2", (d) => d.chart_line_y2 || 0)
      .attr("stroke", (d) => d.chart_color)
      .attr("stroke-width", 1)
      .style("opacity", vis === "chart" ? 1 : 0);

    lineUpdate.exit().remove();

    lineUpdate
      .transition()
      .duration(1500)
      .attr("x1", (d) => d.chart_line_x1)
      .attr("x2", (d) => d.chart_line_x2)
      .attr("y1", (d) => d.chart_line_y1 || 0)
      .attr("y2", (d) => d.chart_line_y2 || 0)
      .style("opacity", vis === "chart" ? 1 : 0);

    return () => {};
  }, [
    vis,
    chartfeatureID,
    view,
    population,
    logScale,
    regionLevel,
    allMetroData,
    logScale,
  ]);

  return null;
};

export default Chart;
