import { useEffect } from "react";
import * as d3 from "d3";
import { useRecoilValue } from "recoil";
import format from "res/formats";
import { visState, populationState, viewState, plotYAxisState, plotXAxisState, 
  yLogScaleState, xLogScaleState, regionLevelState} from "recoil/atoms";
import { mainVisDimensionsState, varKeys, metroDataSlice} from "recoil/selectors";

export const plotXScale = (
  metroData,
  population,
  xVar,
  xVarKey,
  dimensions,
  logScale
) => {
  const { margin, width } = dimensions;
  const scale = logScale ? d3.scaleSymlog() : d3.scaleLinear();
  return (
    scale
      .domain(d3.extent(metroData, (d) => d[`${xVarKey}`] || 0))
      // .nice()
      .range([0 + margin.left, width - margin.right - 25])
  );
};

export const plotX = (
  d,
  metroData,
  population,
  xVar,
  xVarKey,
  dimensions,
  logScale
) => {
  const result = plotXScale(
    metroData,
    population,
    xVar,
    xVarKey,
    dimensions,
    logScale
  )(d[`${xVarKey}`] || 0);

  return result;
};

export const plotYScale = (
  metroData,
  population,
  yVar,
  yVarKey,
  dimensions,
  logScale = false
) => {
  const { margin, height } = dimensions;
  const scale = logScale ? d3.scaleSymlog() : d3.scaleLinear();
  // console.log(d3.extent(metroData, (d) => d[`${population}_${yVarKey}`]));
  return (
    scale
      .domain(d3.extent(metroData, (d) => d[`${yVarKey}`] || 0))
      // .nice()
      .range([height - 0.4 * margin.bottom, 0 + margin.top + 20])
  );
};

export const plotY = (
  d,
  metroData,
  population,
  yVar,
  yVarKey,
  dimensions,
  logScale
) => {
  return plotYScale(
    metroData,
    population,
    yVar,
    yVarKey,
    dimensions,
    logScale
  )(d[`${yVarKey}` || 0]);
};

const Plot = ({ svgRef }) => {
  const dimensions = useRecoilValue(mainVisDimensionsState);
  const vis = useRecoilValue(visState);
  const allMetroData = useRecoilValue(metroDataSlice);
  const population = useRecoilValue(populationState);
  const yVar = useRecoilValue(plotYAxisState);
  const xVar = useRecoilValue(plotXAxisState);
  const yVarKey = useRecoilValue(varKeys(yVar));
  const xVarKey = useRecoilValue(varKeys(xVar));
  const yLogScale = useRecoilValue(yLogScaleState);
  const xLogScale = useRecoilValue(xLogScaleState);
  const regionLevel = useRecoilValue(regionLevelState);

  // initialize and draw axes
  useEffect(() => {
    const metroData = allMetroData.filter((m) => m.level === regionLevel);
    const svg = d3.select(svgRef.current);

    // Add X axis
    var xAxisGroup = svg
      .append("g")
      .attr("class", "x axis plot-axis plot-x-axis")
      .attr(
        "transform",
        "translate(0," +
          (dimensions.height - 0.4 * dimensions.margin.bottom) +
          ")"
      )
      .style("opacity", 0);

    var xAxis = d3
      .axisBottom()
      .scale(
        plotXScale(metroData, population, xVar, xVarKey, dimensions, xLogScale)
      )
      .ticks(5)
      .tickFormat((n) => format(xVar, n, false));
    xAxisGroup.call(xAxis);

    // Add Y axis
    var yAxisGroup = svg
      .append("g")
      .attr("class", "y axis plot-axis plot-y-axis")
      .attr("transform", "translate(" + dimensions.margin.left + ",0)")
      .style("opacity", 0);

    var yAxis = d3
      .axisLeft()
      .scale(
        plotYScale(metroData, population, yVar, yVarKey, dimensions, yLogScale)
      )
      .ticks(5)
      .tickFormat((n) => format(yVar, n, false));

    yAxisGroup.call(yAxis);

    return () => {};
  }, []);

  // update scales and axese on options changes
  useEffect(() => {
    const metroData = allMetroData.filter((m) => m.level === regionLevel);
    const svg = d3.select(svgRef.current);
    // svg
    //   .selectAll(".plot-axis")
    //   .transition()
    //   .duration(1500)
    //   .style("opacity", vis === "plot" ? 1 : 0);

    // Update the Axis
    var xAxis = d3
      .axisBottom()
      .scale(
        plotXScale(metroData, population, xVar, xVarKey, dimensions, xLogScale)
      )
      .ticks(5)
      .tickFormat((n) => format(xVar, n, false));
    var yAxis = d3
      .axisLeft()
      .scale(
        plotYScale(metroData, population, yVar, yVarKey, dimensions, yLogScale)
      )
      .ticks(5)
      .tickFormat((n) => format(yVar, n, false));

    svg
      .selectAll(".plot-y-axis")
      .transition()
      .duration(1500)
      .style("opacity", vis === "plot" ? 1 : 0)
      .call(yAxis);

    svg
      .selectAll(".plot-x-axis")
      .transition()
      .duration(1500)
      .style("opacity", vis === "plot" ? 1 : 0)
      .call(xAxis);

    return () => {};
  }, [vis, yVar, xVar, allMetroData, yLogScale, xLogScale, regionLevel]);

  return null;
};

export default Plot;
