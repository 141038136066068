import React from "react";
import { useRecoilValue } from "recoil";
import { selectedMetroState, selectedMetroIndexScoreState } from "recoil/selectors";
import { foregroundColorFor } from "utils/color";
import InfoTip from "components/InfoTip";
import styled from "styled-components";

const SpanEntre = styled.span`
  background-color:#fff0;


  div#index-title {
    border: 1px solid #D2D2D200;
    font-weight: bold;
    font-size:clamp(14px, 0.9vw, 16px);
    text-align:center;  
    color: rgb(71, 71, 71);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #FDF8F8;
    padding: clamp(6px, 0.5vw, 12px);
  }

  div#index-number {
    border: 1px solid #D2D2D200;
    font-weight: bold;
    font-size:clamp(14px,0.9vw,16px);
    text-align:center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 7px 8px;
  }

`;

function IndexScore() {
  const metro = useRecoilValue(selectedMetroState);
  const score = useRecoilValue(selectedMetroIndexScoreState);

  return (
    <>
      <InfoTip name="indexNumber" nowrap={true}>
        <SpanEntre
          data-tip
          data-for="indexNumberTip"
          className="info-index"
          style={{flexDirection: "row", display: "inline-flex"}}
        >
          <div id="index-title"> 
            Entrepreneurship Equity Score
          </div>
          <div id="index-number" style={{backgroundColor: metro.bubble_color, color: metro.bubble_color && foregroundColorFor(metro.bubble_color) }}>
            {score}
          </div>
        </SpanEntre>
       
      </InfoTip>
    </>
  );
}

export default IndexScore;
