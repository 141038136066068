const tips = {
  achievable: {
    content: (args) => `The statistical prediction of potential achievable impact.`,
  },

  actualCounted: {
    content: (args) => `The calculated value based off reported data.`,
  },

  actualEstimate: {
    content: (args) => `The calculated value based off the sum of reported and estimated missing data.`,
  },

  alertLowPower: {
    content: (args) =>
      "This geographic area's data has low reported data and its numbers may be unreliable.",
  },

  alertNoData: {
    content: (args) =>
      "This geographic area's data has no reported data and its numbers are not reliable enough to model.",
  },
  
  couldHave: {
    content: (args) => "Amount of achievable impact since the year 2000.",
  },

  everyoneElse: {
    content: (args) =>
      `Click to toggle on/off. Reported value based on our dataset of non-${args.pop} entrepreneurs`,
  },

  gap: {
    content: (args) =>
      `This shows the ${args.pop} equity potential of a particular geographic area. Red color indicates lower equity while green indicates higher equity potential.`,
  },
  indexNumber: {
    content: (args) =>
      `The Entrepreneurship Equity Scores shows: (1.) How well represented target entrepreneurs are in a region, e.g., LGBTQ entrepreneurs, and (2.) whether there is a gap in what we estimate to be achieved and what we have measured with reported data. A high Entrepreneurship Equity Score shows this given location performs well in terms of minority representation and achievement in terms of funding, jobs, patents, and exits.`,
    place: "left",
  },

  numberEnts: {
    content: (args) => "Number of all entrepreneurs in this location",
  },
  population: {
    place: "bottom",
    content: (args) =>
      "Choose the population of interest <br/> to learn the impact of",
  },
  scale: {
    content: (args) =>
      "Shape size corresponds to the impact of the selected area. The larger the shape, the greater the impact it has.",
  },
  whyNotMyMetro: {
    content: (args) =>
      "Only Statistical Areas with at least 50 total high-growth entrepreneurs are displayed",
  },

  PanelAllEntrepreneurs: {
    content: (args) => `All entrepreneurs reported through our data sources.`,
  },
  PanelPopulation: {
    content: (args) => `The measured population of this geographic entity.`,
  },
  PanelGDPperCap: {
    content: (args) => `This is the Gross Domestic Product per Capita`,
  },
  PanelGDP: {
    content: (args) => `This is the Gross Domestic Product for this location.`,
  },
  PanelReported: {
    content: (args) => `This is the current counted and reported entrepreneur numbers and statistical results.`,
  },
  PanelEstimate: {
    content: (args) => `This is the estimated, actual values of current ${args.pop} entrepreneur numbers and economic impact.`,
  },
  PanelAchievables: {
    content: (args) => `This is what we believe could be the current ${args.pop} numbers and statistical results if ${args.pop} entrepreneurs could be performing as well as best in class regions, i.e, the San Francisco Bay Area.`,
  },
};

export default tips;
