import React from "react";
import { Button } from "semantic-ui-react";
import { useRecoilState } from "recoil";
import { metroBreakoutState } from "recoil/atoms";
import Industries from "./Industries";
import InfoCard from "./InfoCard";
import Policies from "./Policies";
import SubPlots from "./SubPlots";
import styled from "styled-components";

const StyledDiv = styled.div`
  position: relative;
  padding-top: 1em;
  padding-bottom: 2em;
`;

const StyledButton = styled.div`
  margin: 0 0 5px;
  text-align:center;
  width:103%;

  .button {
    width:fit-content;
    font-size:0.6rem;
    border solid 1px #CFCFCF;
  }

  .ui.button, .ui.active.button {
    width:fit-content;
    font-size:clamp(9px, 0.9vw, 12px);
    border solid 1px #CFCFCF;
    box-shadow:0px 0px 5px #a4a0a0;
    padding:0.55vw 0.75vw;
  }

`;

function OverviewData() {
  const [activeTab, setActiveTab] = useRecoilState(metroBreakoutState);

  const handleSubPlotsClick = () => {
    setActiveTab("subplots");
  };
  const handleOverviewCardClick = () => {
    setActiveTab("overview");
  };
  const handleIndustriesClick = () => {
    setActiveTab("industries");
  };
  const handlePoliciesClick = () => {
    setActiveTab("policies");
  };

  return (
    <>
      <StyledButton> 
        <Button 
            color={activeTab === "overview" ? "blue" : "grey"}
            active={activeTab === "overview"}
            onClick={handleOverviewCardClick}
          >
            Statistics
          </Button>
          <Button
            color={activeTab === "subplots" ? "blue" : "grey"}
            active={activeTab === "subplots"}
            onClick={handleSubPlotsClick}
          >
            Graphs
          </Button>
          <Button
            color={activeTab === "policies" ? "blue" : "grey"}
            active={activeTab === "policies"}
            onClick={handlePoliciesClick}
          >
            Policies
          </Button>
          <Button
            color={activeTab === "industries" ? "blue" : "grey"}
            active={activeTab === "industries"}
            onClick={handleIndustriesClick}
          >
            Industry
          </Button>
      </StyledButton>
      <StyledDiv>
        <InfoCard active={activeTab === "overview"} />
        <SubPlots active={activeTab === "subplots"} />
        <Policies active={activeTab === "policies"} />
        <Industries active={activeTab === "industries"} />
      </StyledDiv>
    </>
  );
}

export default OverviewData;
