import { selector, selectorFamily } from "recoil";
import merge from "mergerino/dist/mergerino.es5";
import * as atoms from "recoil/atoms";
import * as d3 from "d3";
import * as topojson from "topojson-client";
import us from "assets/counties-10m.json";
import { projection } from "sections/Vis/Map";
import { plotX, plotY } from "../sections/Vis/Plot";
import {
  chartX,
  chartY,
  chartYZero,
  chartHeight,
  chartWidth,
} from "../sections/Vis/Chart";
import settings from "res/settings";
import { bubbleSize, bubbleColor } from "../sections/Vis/VisD3";
import format from "res/formats";
import moment from "moment";
const clone = require("rfdc")();

export const analyticsDataState = selector({
  key: "analyticsDataState",
  get: ({ get }) => {
    return {
      metro: get(selectedMetroState).city,
      population: get(atoms.populationState),
      main_vis: get(atoms.visState),
      view: { view1: "totals", view2: "means" }[get(atoms.viewState)],
      breakout: get(atoms.metroBreakoutState),
      everyone_else_bar: get(atoms.subPlotNonPopState),
      plot_y_feature: get(atoms.plotYAxisState).replace("_sub", ""),
      plot_x_feature: get(atoms.plotXAxisState).replace("_sub", ""),
      chart_feature: get(selectedChartFeatureState).text,
    };
  },
});

export const mainVisDimensionsState = selector({
  key: "mainVisDimensionsState",
  get: ({ get }) => {
    return settings.vis.svg.dims;
  },
});

export const visBackgroundColorState = selector({
  key: "visBackgroundColorState",
  get: ({ get }) => {
    return {
      map: "linear-gradient(rgba(33, 133, 208, 0.4), rgba(33, 133, 208, 0.4))",
      plot: "linear-gradient(rgba(33, 133, 208, 0.4), rgba(90, 156, 206, 0.4) 35%)",
      chart: "linear-gradient(rgba(33, 133, 208, 0.4), rgba(90, 156, 206, 0.4) 35%)",
      // plot: "rgba(33, 133, 208, .5)",
      // chart: "rgba(33, 133, 208, .5)",
    }[get(atoms.visState)];
  },
});

export const selectedMetroState = selector({
  key: "selectedMetroState",
  get: ({ get }) => {
    const metros = get(metroDataSlice);
    const metroID = get(atoms.selectedMetroIDState);
    if (metroID === 0) {
      return get(nationalMetro);
    } else {
      return metros.find((metro) => metro.geo_code === metroID);
    }
  },
});

export const industryState = selector({
  key: "industryState",
  get: ({ get }) => {
    const metro = get(selectedMetroState);
    const population = get(atoms.populationState);
    const view = get(atoms.viewState);
    const Industry = metro.Industry;

    if (true) {
      const industries = Object.values(Industry)
        .sort((a, b) =>
          d3.descending(a[`${population}_Count`], b[`${population}_Count`])
        )
        .map((ind) => {
          return merge(ind, {
            industry: ind.Industry,
            color: ind[`${population}_${view}_Index_Score`], // Gets the data between 1-100
            id: ind.IndustryAbbreviated,
            DisplayName: ind.Industry,
            sector: ind.IndustrySector,
            all_count: ind.All_Count,
            weight: ind.CompositeScore,
            population_count: ind[`${population}_Count`], 
            index_score: ind[`${population}_${view}_Index_Score`],
            likelihood_ratio: ind.Likelihood
          });
        });
      return industries.filter((ind) => ind.All_Count > 0);
    } else {
      return [];
    }
  },
});

export const industryMapState = selector({
  key: "industryMapState",
  get: ({ get }) => {
    const industries = get(industryState);
    var output = {};
    industries.forEach((industry) => (output[industry.id] = industry));

    return output;
  },
});

export const selectedIndustrySector = selector({
  key: "selectedIndustrySector",
  get: ({ get }) => {
    const id = get(atoms.selectedIndustryIDState);
    const industries = get(industryMapState);
    return id && industries ? industries[id] : null;
  },
});

export const industryPolygonMap = selector({
  key: "industryPolygonMap",
  get: ({ get }) => {
    let output = {};
    const polygons = get(atoms.industryPolygonsState);
    polygons &&
      polygons.forEach((p) => {
        output[p.site.originalObject.data.originalData.id] = {
          weight: p.site.weight,
          position: [p.site.x, p.site.y],
        };
      });
    return output;
  },
});

export const allIndustriesQuery = selector({
  key: "allIndustriesQuery",
  get: ({ get }) => {
    const metros = get(metroDataSlice);
    return metros.Industry;
  },
});

export const hoveredMetroState = selector({
  key: "hoveredMetroState",
  get: ({ get }) => {
    const metros = get(metroDataSlice);
    const metroID = get(atoms.hoveredMetroIDState);
    if (metroID === 0) {
      return get(nationalMetro);
    } else {
      return metroID
        ? metros.find((metro) => metro.geo_code === metroID)
        : null;
    }
  },
});

export const selectedMetroNameState = selector({
  key: "selectedMetroNameState",
  get: ({ get }) => {
    const name = get(selectedMetroState).city;
    return name === "National" ? "The United States" : name;
  },
});

export const selectedMetroIndexScoreState = selector({
  key: "selectedMetroIndexScoreState",
  get: ({ get }) => {
    const metro = get(selectedMetroState);
    const population = get(atoms.populationState);
    const view = get(atoms.viewState);
    return d3.format(".0f")(metro[`${population}_Index_Score_${view}_Actual`]);
  },
});

export const hoveredMetroIndexScoreState = selector({
  key: "hoveredMetroIndexScoreState",
  get: ({ get }) => {
    const metros = get(metroDataSlice);
    const metroID = get(atoms.hoveredMetroIDState);
    const metro = metros.find((metro) => metro.geo_code === metroID);

    const population = get(atoms.populationState);
    const view = get(atoms.viewState);
    return metroID
      ? d3.format(".0f")(metro[`${population}_Index_Score_${view}_Actual`])
      : null;
  },
});

export const selectedMetroCouldHaveJobsState = selector({
  key: "selectedMetroCouldHaveJobsState",
  get: ({ get }) => {
    const metro = get(selectedMetroState);
    const population = get(atoms.populationState);
    return d3.format(",")(
      Math.floor(
        metro[`${population}_Jobs_Total_Achievable`] -
          metro[`${population}_Jobs_Total_Actual`]
      )
    );
  },
});

export const activeMetrosState = selector({
  key: "activeMetrosState",
  get: ({ get }) => {
    const allMetros = get(metroDataSlice);
    const regionLevel = get(atoms.regionLevelState);
    const result = allMetros.filter((m) => m.level === regionLevel);

    return result;
  },
});

export const metroData = selector({
  key: "metroData",
  get: ({ get }) => {
    return get(metroDataQuery).arr;
  },
});

export const stateData = selector({
  key: "stateData",
  get: ({ get }) => {
    return get(stateDataQuery).arr;
  },
});

export const countryData = selector({
  key: "countryData",
  get: ({ get }) => {
    return get(countryDataQuery).arr;
  },
});

export const metroDataSlice = selector({
  key: "metroDataSlice",
  get: ({ get }) => {
    const dimensions = get(mainVisDimensionsState);
    const regionLevel = get(atoms.regionLevelState);
    const theMetros = get(metroDataQuery).arr;
    const theStates = get(stateDataQuery).arr;
    const metroData = [...theMetros, ...theStates];

    //console.log(JSON.stringify(theMetros));

    // map stuff
    const population = get(atoms.populationState); // This is LGBTQ, Women or all
    const view = get(atoms.viewState); // This is Mean, Totals
    const { projection } = get(geoState); // Coordinate display
    
    // plot stuff
    const yVar = get(atoms.plotYAxisState);
    const xVar = get(atoms.plotXAxisState);
    const xLogScale = get(atoms.xLogScaleState);
    const yLogScale = get(atoms.yLogScaleState);

    // chart stuff
    const chartLogScale = get(atoms.chartLogScaleState);
    const chartBubbleSize = 5;
    const chartFeature = get(atoms.chartFeatureState);

    const sortedMetroData = clone(metroData).sort((a, b) =>
      d3.descending(
        chartHeight(
          a,
          metroData,
          population,
          view,
          chartFeature,
          get(varKeys(chartFeature)),
          dimensions
        ),
        chartHeight(
          b,
          metroData,
          population,
          view,
          chartFeature,
          get(varKeys(chartFeature)),
          dimensions
        )
      )
    );

    const activeMetros = (m) =>
      sortedMetroData.filter((m) => m.level === regionLevel);
      //print regionLevel;
    const newMetroData = sortedMetroData.map((m, i) => {
      const size = m[`${population}_ImpactScale_${view}_Actual`];
      const color = m[`${population}_Index_Score_${view}_Actual`];
      const newData = merge(m, {
        view_prefix: view === "Mean" ? "Mean" : "Total",
        population_text: population,
        size: size,
        color: color,
        bubble_size: bubbleSize(size),
        bubble_color: bubbleColor(color),
        gdp_per_capita: m[`GDP_Per_Capita`],
        gdp: m[`GDP`],
        population: m[`Population`],
        index_number: m[`${population}_Index_Score_${view}_Actual`],
        impact_scale: m[`${population}_ImpactScale_${view}_Actual`],

        funding: m[`${population}_Funding_${view}_Actual`],
        funding_estimate: m[`${population}_Funding_${view}_Estimated`],
        funding_achievable: m[`${population}_Funding_${view}_Achievable`],
        funding_everyone_else: m[`All_Funding_${view}_Actual`],

        jobs: m[`${population}_Jobs_${view}_Actual`],
        jobs_estimate: m[`${population}_Jobs_${view}_Estimated`],
        jobs_achievable: m[`${population}_Jobs_${view}_Achievable`],
        jobs_everyone_else: m[`All_Jobs_${view}_Actual`],

        patents: m[`${population}_Patents_${view}_Actual`],
        patents_estimate: m[`${population}_Patents_${view}_Estimated`],
        patents_achievable: m[`${population}_Patents_${view}_Achievable`],
        patents_everyone_else: m[`All_Patents_${view}_Actual`],

        exits_count: m[`${population}_ExitCounts_${view}_Actual`],
        exits_count_estimate: m[`${population}_ExitCounts_${view}_Estimated`],
        exits_count_achievable: m[`${population}_ExitCounts_${view}_Achievable`],
        exits_count_everyone_else: m[`All_ExitCounts_${view}_Actual`],

        // exits_value: m[`${population}_exit_value_sub_${view}`],
        // exits_value_estimate: m[`${population}_exit_value_estimate_sub_${view}`],
        // exits_value_achievable: m[`${population}_exit_value_pred_sub_${view}`],
        // exits_value_everyone_else: m[`${population}_non_exit_value_sub_${view}`],

        entrepreneurs: m[`${population}_Ent_Pop_Actual`],
        entrepreneurs_estimate: m[`${population}_Ent_Pop_Estimated`],
        entrepreneurs_achievable: m[`${population}_Ent_Pop_Achievable`],
        all_entrepreneurs: m[`All_Ent_Pop_Actual`],
        low_statistical_power: m[`${population}_LowStatisticalPower`],
        No_Statistical_Data: m[`${population}_No_Statistical_Data`],
        map_x: projection([m.longitude, m.latitude])[0] - bubbleSize(size),
        map_y: projection([m.longitude, m.latitude])[1] - bubbleSize(size),
        map_rx: bubbleSize(size),
        map_ry: bubbleSize(size),
        map_width: bubbleSize(size) * 2,
        map_height: bubbleSize(size) * 2,
        map_color: bubbleColor(color),
        plot_x:
          plotX(
            m,
            activeMetros(m),
            population,
            xVar,
            get(varKeys(xVar)),
            dimensions,
            xLogScale
          ) - bubbleSize(size),
        plot_y:
          plotY(
            m,
            activeMetros(m),
            population,
            yVar,
            get(varKeys(yVar)),
            dimensions,
            yLogScale
          ) - bubbleSize(size),
        plot_rx: bubbleSize(size),
        plot_ry: bubbleSize(size),
        plot_width: bubbleSize(size) * 2,
        plot_height: bubbleSize(size) * 2,
        plot_color: bubbleColor(color),
        chart_x: chartX(m.city, activeMetros(m), dimensions) - chartBubbleSize,
        chart_y:
          chartY(
            m,
            activeMetros(m),
            population,
            view,
            chartFeature,
            get(varKeys(chartFeature)),
            dimensions,
            chartLogScale
          ) - chartBubbleSize,
        chart_rx: chartBubbleSize,
        chart_ry: chartBubbleSize,
        chart_height: 2 * chartBubbleSize,
        chart_width: 2 * chartBubbleSize,
        chart_color: "#1f78b4",
        chart_line_x1: chartX(m.city, activeMetros(m), dimensions),
        chart_line_x2: chartX(m.city, activeMetros(m), dimensions),
        chart_line_y1:
          chartY(
            m,
            activeMetros(m),
            population,
            view,
            chartFeature,
            get(varKeys(chartFeature)),
            dimensions,
            chartLogScale
          ) + chartBubbleSize,
        chart_line_y2: chartYZero(
          m,
          activeMetros(m),
          population,
          view,
          chartFeature,
          get(varKeys(chartFeature)),
          dimensions,
          chartLogScale
        ),
        industries: m.Industry,
        policies: m.Policies,
        womenmeanpolcies: m.WOMEN_Mean_Policy,
        womentotalpolcies: m.WOMEN_Total_Policy,
        policySuggested: m.level === "metro" ? GetSuggestedPolicy(theMetros): GetSuggestedPolicy(theStates),
      });
      return newData;
    });
    return newMetroData;
  },
});

export const nationalMetro = selector({
  key: "nationalMetro",
  get: ({ get }) => {
    const countryData = get(countryDataQuery);
    const activeCountry = get(atoms.activeCountryState);

    const theStates = get(stateDataQuery).arr;
    const view = get(atoms.viewState);
    const population = get(atoms.populationState);
    const m = countryData.arr.find(
      (country) => country.Country === activeCountry
    );

    const size = m[`${population}_ImpactScale_${view}_Actual`];
    const color = m[`${population}_Index_Score_${view}_Actual`];

    return merge(m, {
      size: size,
      color: color,
      bubble_size: bubbleSize(size),
      bubble_color: bubbleColor(color),
      gdp_per_capita: m[`GDP_Per_Capita`],
      gdp: m[`GDP`],
      population: m[`Population`],
      index_number: m[`${population}_Index_Score_${view}_Actual`],
      city: m.DisplayName || "N/A",
      view_prefix: view === "Mean" ? "Mean" : "Total",

      funding: m[`${population}_Funding_${view}_Actual`],
      funding_estimate: m[`${population}_Funding_${view}_Estimated`],
      funding_achievable: m[`${population}_Funding_${view}_Achievable`],
      funding_everyone_else: m[`All_Funding_${view}_Actual`],

      jobs: m[`${population}_Jobs_${view}_Actual`],
      jobs_estimate: m[`${population}_Jobs_${view}_Estimated`],
      jobs_achievable: m[`${population}_Jobs_${view}_Achievable`],
      jobs_everyone_else: m[`All_Jobs_${view}_Actual`],

      patents: m[`${population}_Patents_${view}_Actual`],
      patents_estimate: m[`${population}_Patents_${view}_Estimated`],
      patents_achievable: m[`${population}_Patents_${view}_Achievable`],
      patents_everyone_else: m[`All_Patents_${view}_Actual`],

      exits_count: m[`${population}_ExitCounts_${view}_Actual`],
      exits_count_estimate: m[`${population}_ExitCounts_${view}_Estimated`],
      exits_count_achievable: m[`${population}_ExitCounts_${view}_Achievable`],
      exits_count_everyone_else: m[`All_ExitCounts_${view}_Actual`],

      entrepreneurs: m[`${population}_Ent_Pop_Actual`],
      entrepreneurs_estimate: m[`${population}_Ent_Pop_Estimated`],
      entrepreneurs_achievable: m[`${population}_Ent_Pop_Achievable`],
      all_entrepreneurs: m[`All_Ent_Pop_Actual`],
      low_statistical_power: m[`${population}_LowStatisticalPower`],
      No_Statistical_Data: m[`${population}_No_Statistical_Data`],
      population_text: population,
      policySuggested: GetSuggestedPolicy(theStates),
    });
  },
});

export const geoState = selector({
  key: "geoState",
  get: ({ get }) => {
    const { height, width } = get(mainVisDimensionsState);
    const geojson = topojson.feature(us, us.objects.states);
    // const projection = d3.geoAlbersUsa();
    const path = d3.geoPath().projection(projection);
    // projection.fitExtent(
    //   [
    //     [25, 25],
    //     [width - 25, height - 25],
    //   ],
    //   geojson
    // );
    return { geojson, projection, path };
  },
});

/*
* Creates a list of objects in the features that will display table information on a hovered or 
* clicked on field in the map display.
*/
export const chartFeaturesState = selector({
  key: "chartFeaturesState",
  get: ({ get }) => {
    let features = [
      {
        key: "funding",
        value: "funding",
        text: "Funding",
        format: "money",
      },
      {
        key: "jobs",
        value: "jobs",
        text: "Jobs",
        format: "count",
      },
      {
        key: "patents",
        value: "patents",
        text: "Patents",
        format: "count",
      },
      {
        key: "exits_count",
        value: "exits_count",
        text: "Exits (count)",
        format: "count",
      },
      {
        key: "ent_proportion",
        value: "ent_proportion",
        text: "Entrepreneur Proportion",
        format: "decimal",
      },
      {
        key: "index_number",
        value: "index_number",
        text: "Index Score",
        format: "count",
      },
    ];

    return features;
  },
});

/*
 * Updates the selected point (for states) on the map to show that it is selected to the user. 
 * (this is shown through subtle outline changes to the state point)
*/ 
export const selectedChartFeatureState = selector({
  key: "selectedChartFeatureState",
  get: ({ get }) => {
    const features = get(chartFeaturesState);
    const feature = get(atoms.chartFeatureState);
    if (features) {
      return features.find((f) => f.key === feature);
    } else {
      return null;
    }
  },
});

/*
* Creates a list of objects in the features that will display table information on a hovered or 
* clicked on field in the graph plot display.
*/
export const plotFeaturesState = selector({
  key: "plotFeaturesState",
  get: ({ get }) => {
    return [
      {
        key: "gdp_per_capita",
        value: "gdp_per_capita",
        text: "GDP per Capita",
      },
      { key: "population", value: "population", text: "Population" },
      { key: "index_number", value: "index_number", text: "Index Score" },
      {
        key: "jobs",
        value: "jobs",
        text: "Jobs Created",
      },
      {
        key: "funding",
        value: "funding",
        text: "Funding",
      },
      {
        key: "exits_count",
        value: "exits_count",
        text: "Exits",
      },
      {
        key: "patents",
        value: "patents",
        text: "Patents",
      },
    ];
  },
});

/*
* This sets the variables that will display the information for the points that are hovered over in the map.
*/
export const varKeys = selectorFamily({
  key: "varKeys",
  get:
    (varName) =>
    ({ get }) => {
      const view = get(atoms.viewState);
      const population = get(atoms.populationState);

      return {
        gdp_per_capita: `GDP_Per_Capita`,
        gdp: `GDP`,
        population: `Population`,
        index_number: `${population}_Index_Score_${view}_Actual`,
        jobs: `${population}_Jobs_${view}_Actual`,
        funding: `${population}_Funding_${view}_Actual`,
        exits_count: `${population}_ExitCounts_${view}_Actual`,
        patents: `${population}_Patents_${view}_Actual`,
        ent_proportion: `${population}_Ent_Proportion_Actual`,
        funding_estimate: `${population}_Funding_${view}_Estimated`,
        jobs_estimate: `${population}_Jobs_${view}_Estimated`,
        patents_estimate: `${population}_Patents_${view}_Estimated`,
        exits_count_estimate: `${population}_ExitCounts_${view}_Estimated`,
        entrepreneurs_estimate: `${population}_Ent_Pop_Estimated`,
      }[varName];
    },
});

/*
* This holds the information for the bar charts on the left side of the screen. This gets updated
* when a new point is selected in the map.
*/
export const subPlotData = selectorFamily({
  key: "subPlotData",
  get:
    (feature) =>
    ({ get }) => {
      const metro = get(selectedMetroState);
      const nonPop = get(showSubPlotNonPopState);
      const data = [
        {
          name: "Estimated Total",
          x: 1,
          y: metro[`${feature}_estimate`],
          color: "rgb(13, 49, 107)",
          value: metro[`${feature}_estimate`] > 0 && metro[`${feature}_estimate`] < 1 ? format("mean", metro[`${feature}_estimate`], false) : format(feature, metro[`${feature}_estimate`], true),
        },
        {
          name: "Reported",
          x: 1,
          y: metro[feature],
          color: "#ad8961",
          value: metro[feature] > 0 && metro[feature] < 1 ? format("mean", metro[feature], false) : format(feature, metro[feature], true),
        },
        {
          name: "Achievable",
          x: 2,
          y: metro[`${feature}_achievable`],
          color: "rgb(91, 55, 102)",
          value: metro[`${feature}_achievable`] > 0 && metro[`${feature}_achievable`] < 1 ? format("mean", metro[`${feature}_achievable`], false) : format(feature, metro[`${feature}_achievable`], true),
        },
        {
          name: `Everyone Else`,
          x: 3,
          y: metro[`${feature}_everyone_else`],
          color: "#39343b",
          value: metro[`${feature}_everyone_else`] > 0 && metro[`${feature}_everyone_else`] < 1 ? format("mean", metro[`${feature}_everyone_else`], false) : format(feature, metro[`${feature}_everyone_else`], true),
        },
      ];
      // console.log("subPlotData", output, metro);
      return nonPop ? data : data.slice(0, 3);
    },
});

export const showSubPlotNonPopState = selector({
  key: "showSubPlotNonPopState",
  get: ({ get }) => {
    const nonPop = get(atoms.subPlotNonPopState);
    const population = get(atoms.populationState);
    return nonPop && population !== "All";
  },
});

export const lastUpdated = selector({
  key: "lastUpdated",
  get: ({ get }) => {
    return moment().format("llll");
  },
});

//
// Async data fetch selectors
//

/**
 * Get metro data
 */

export const metroDataQuery = selector({
  key: "metroDataQuery",
  get: async ({ get }) => {
    const response = await fetch(
      "https://storage.googleapis.com/index-frontend-data/PRODUCTION/metros.json"
    );

    const jsonData = await response.json();
    const data = jsonData.data;
    //console.log("Metros Loaded: " + Object.values(data).length);

    let newDataArray = [];
    let newDataObject = {};

    // itterate over each metro, change some variable names, fill in some missing data, and then
    // store away in both Object and Array shapes for later access
    Object.values(data)
      //.filter((state) => state.Country == "USA")
      .forEach((rawData) => {
      newDataObject = merge(newDataObject, {
        [rawData.Geo_Name]: {
          ...rawData,
          level: "metro",
          geo_code: rawData.Geo_Code,
          city: rawData.DisplayName || "N/A",
          longitude: rawData.Geo_Longitude,
          latitude: rawData.Geo_Latitude,

          // Index Score
          All_Index_Score_Mean_Actual: rawData.All_Index_Score_Mean_Actual,
          Women_Index_Score_Mean_Actual: rawData.Female_Index_Score_Mean_Actual,
          LGBTQ_Index_Score_Mean_Actual: rawData.LGBTQ_Index_Score_Mean_Actual,
          All_Index_Score_Total_Actual: rawData.All_Index_Score_Total_Actual,
          Women_Index_Score_Total_Actual: rawData.Female_Index_Score_Total_Actual,
          LGBTQ_Index_Score_Total_Actual: rawData.LGBTQ_Index_Score_Total_Actual,
          
          // Low Stat Power
          LGBTQ_LowStatisticalPower: rawData.LGBTQ_Low_Statistical_Power,
          Women_LowStatisticalPower: rawData.Female_Low_Statistical_Power,
          All_LowStatisticalPower: false,

          // No Data power
          All_No_Statistical_Data: rawData.All_Lacking_Ent_Subfactor_Data,
          Women_No_Statistical_Data: rawData.Female_Lacking_Ent_Subfactor_Data,
          LGBTQ_No_Statistical_Data: rawData.LGBTQ_Lacking_Ent_Subfactor_Data,

          // Funding
          All_Funding_Mean_Achievable: rawData.All_Funding_Mean_Achievable,
          All_Funding_Mean_Actual: rawData.All_Funding_Mean_Actual,
          All_Funding_Mean_Estimated: rawData.All_Funding_Mean_Estimated,
          All_Funding_Total_Achievable: rawData.All_Funding_Total_Achievable,
          All_Funding_Total_Actual: rawData.All_Funding_Total_Actual,
          All_Funding_Total_Estimated: rawData.All_Funding_Total_Estimated,
          Women_Funding_Mean_Achievable: rawData.Female_Funding_Mean_Achievable,
          Women_Funding_Mean_Actual: rawData.Female_Funding_Mean_Actual,
          Women_Funding_Mean_Estimated: rawData.Female_Funding_Mean_Estimated,
          Women_Funding_Total_Achievable: rawData.Female_Funding_Total_Achievable,
          Women_Funding_Total_Actual: rawData.Female_Funding_Total_Actual,
          Women_Funding_Total_Estimated: rawData.Female_Funding_Total_Estimated,
          LGBTQ_Funding_Mean_Achievable: rawData.LGBTQ_Funding_Mean_Achievable,
          LGBTQ_Funding_Mean_Actual: rawData.LGBTQ_Funding_Mean_Actual,
          LGBTQ_Funding_Mean_Estimated: rawData.LGBTQ_Funding_Mean_Estimated,
          LGBTQ_Funding_Total_Achievable: rawData.LGBTQ_Funding_Total_Achievable,
          LGBTQ_Funding_Total_Actual: rawData.LGBTQ_Funding_Total_Actual,
          LGBTQ_Funding_Total_Estimated: rawData.LGBTQ_Funding_Total_Estimated,

          // Entrepreneur Proportion
          All_Ent_Proportion_Actual:rawData.All_Ent_Proportion_Actual,
          All_Ent_Proportion_Estimated:rawData.All_Ent_Proportion_Estimated,
          All_Ent_Proportion_Achievable:rawData.All_Ent_Proportion_Achievable,
          LGBTQ_Ent_Proportion_Actual:rawData.LGBTQ_Ent_Proportion_Actual,
          LGBTQ_Ent_Proportion_Estimated:rawData.LGBTQ_Ent_Proportion_Estimated,
          LGBTQ_Ent_Proportion_Achievable:rawData.LGBTQ_Ent_Proportion_Achievable,
          Women_Ent_Proportion_Actual:rawData.Female_Ent_Proportion_Actual,
          Women_Ent_Proportion_Estimated:rawData.Female_Ent_Proportion_Estimated,
          Women_Ent_Proportion_Achievable:rawData.Female_Ent_Proportion_Achievable,

          // Impact Score
          All_ImpactScale_Mean_Achievable: rawData.All_ImpactScale_Mean_Achievable,
          All_ImpactScale_Mean_Actual: rawData.All_ImpactScale_Mean_Actual,
          All_ImpactScale_Mean_Estimated: rawData.All_ImpactScale_Mean_Estimated,
          All_ImpactScale_Total_Achievable: rawData.All_ImpactScale_Total_Achievable,
          All_ImpactScale_Total_Actual: rawData.All_ImpactScale_Total_Actual,
          All_ImpactScale_Total_Estimated: rawData.All_ImpactScale_Total_Estimated,
          Women_ImpactScale_Mean_Achievable: rawData.Female_ImpactScale_Mean_Achievable,
          Women_ImpactScale_Mean_Actual: rawData.Female_ImpactScale_Mean_Actual,
          Women_ImpactScale_Mean_Estimated: rawData.Female_ImpactScale_Mean_Estimated,
          Women_ImpactScale_Total_Achievable: rawData.Female_ImpactScale_Total_Achievable,
          Women_ImpactScale_Total_Actual: rawData.Female_ImpactScale_Total_Actual,
          Women_ImpactScale_Total_Estimated: rawData.Female_ImpactScale_Total_Estimated,
          LGBTQ_ImpactScale_Mean_Actual: rawData.LGBTQ_ImpactScale_Mean_Actual,
          LGBTQ_ImpactScale_Mean_Estimated: rawData.LGBTQ_ImpactScale_Mean_Estimated,
          LGBTQ_ImpactScale_Mean_Achievable: rawData.LGBTQ_ImpactScale_Mean_Achievable,
          LGBTQ_ImpactScale_Total_Achievable: rawData.LGBTQ_ImpactScale_Total_Achievable,
          LGBTQ_ImpactScale_Total_Actual: rawData.LGBTQ_ImpactScale_Total_Actual,
          LGBTQ_ImpactScale_Total_Estimated: rawData.LGBTQ_ImpactScale_Total_Estimated, 

          GDP: rawData.GDP,
          GDP_Per_Capita: rawData.GDP_Per_Capita,
          Population: rawData.Population,

          // Population
          All_Ent_Pop_Actual: rawData.All_Ent_Pop_Actual,
          All_Ent_Pop_Estimated: rawData.All_Ent_Pop_Estimated,
          All_Ent_Pop_Achievable: rawData.All_Ent_Pop_Achievable,
          Women_Ent_Pop_Actual: rawData.Female_Ent_Pop_Actual,
          Women_Ent_Pop_Estimated: rawData.Female_Ent_Pop_Estimated,
          Women_Ent_Pop_Achievable: rawData.Female_Ent_Pop_Achievable,
          LGBTQ_Ent_Pop_Actual: rawData.LGBTQ_Ent_Pop_Actual,
          LGBTQ_Ent_Pop_Estimated: rawData.LGBTQ_Ent_Pop_Estimated,
          LGBTQ_Ent_Pop_Achievable: rawData.LGBTQ_Ent_Pop_Achievable,

          // Exit Counts
          All_ExitCounts_Mean_Achievable: rawData.All_ExitCounts_Mean_Achievable,
          All_ExitCounts_Mean_Actual: rawData.All_ExitCounts_Mean_Actual,
          All_ExitCounts_Mean_Estimated: rawData.All_ExitCounts_Mean_Estimated,
          All_ExitCounts_Total_Achievable: rawData.All_ExitCounts_Total_Achievable,
          All_ExitCounts_Total_Actual: rawData.All_ExitCounts_Total_Actual,
          All_ExitCounts_Total_Estimated: rawData.All_ExitCounts_Total_Estimated,
          Women_ExitCounts_Mean_Achievable: rawData.Female_ExitCounts_Mean_Achievable,
          Women_ExitCounts_Mean_Actual: rawData.Female_ExitCounts_Mean_Actual,
          Women_ExitCounts_Mean_Estimated: rawData.Female_ExitCounts_Mean_Estimated,
          Women_ExitCounts_Total_Achievable: rawData.Female_ExitCounts_Total_Achievable,
          Women_ExitCounts_Total_Actual: rawData.Female_ExitCounts_Total_Actual,
          Women_ExitCounts_Total_Estimated: rawData.Female_ExitCounts_Total_Estimated,
          LGBTQ_ExitCounts_Mean_Achievable: rawData.LGBTQ_ExitCounts_Mean_Achievable,
          LGBTQ_ExitCounts_Mean_Actual: rawData.LGBTQ_ExitCounts_Mean_Actual,
          LGBTQ_ExitCounts_Mean_Estimated: rawData.LGBTQ_ExitCounts_Mean_Estimated,
          LGBTQ_ExitCounts_Total_Achievable: rawData.LGBTQ_ExitCounts_Total_Achievable,
          LGBTQ_ExitCounts_Total_Actual: rawData.LGBTQ_ExitCounts_Total_Actual,
          LGBTQ_ExitCounts_Total_Estimated: rawData.LGBTQ_ExitCounts_Total_Estimated,

          // Patents
          All_Patents_Mean_Achievable: rawData.All_Patents_Mean_Achievable,
          All_Patents_Mean_Actual: rawData.All_Patents_Mean_Actual,
          All_Patents_Mean_Estimated: rawData.All_Patents_Mean_Estimated,
          All_Patents_Total_Achievable: rawData.All_Patents_Total_Achievable,
          All_Patents_Total_Actual: rawData.All_Patents_Total_Actual,
          All_Patents_Total_Estimated: rawData.All_Patents_Total_Estimated,
          Women_Patents_Mean_Achievable: rawData.Female_Patents_Mean_Achievable,
          Women_Patents_Mean_Actual: rawData.Female_Patents_Mean_Actual,
          Women_Patents_Mean_Estimated: rawData.Female_Patents_Mean_Estimated,
          Women_Patents_Total_Achievable: rawData.Female_Patents_Total_Achievable,
          Women_Patents_Total_Actual: rawData.Female_Patents_Total_Actual,
          Women_Patents_Total_Estimated: rawData.Female_Patents_Total_Estimated,
          LGBTQ_Patents_Mean_Achievable: rawData.LGBTQ_Patents_Mean_Achievable,
          LGBTQ_Patents_Mean_Actual: rawData.LGBTQ_Patents_Mean_Actual,
          LGBTQ_Patents_Mean_Estimated: rawData.LGBTQ_Patents_Mean_Estimated,
          LGBTQ_Patents_Total_Achievable: rawData.LGBTQ_Patents_Total_Achievable,
          LGBTQ_Patents_Total_Actual: rawData.LGBTQ_Patents_Total_Actual,
          LGBTQ_Patents_Total_Estimated: rawData.LGBTQ_Patents_Total_Estimated,
          
          // Job Counts
          All_Jobs_Mean_Achievable: rawData.All_Jobs_Mean_Achievable,
          All_Jobs_Mean_Actual: rawData.All_Jobs_Mean_Actual,
          All_Jobs_Mean_Estimated: rawData.All_Jobs_Mean_Estimated,
          All_Jobs_Total_Achievable: rawData.All_Jobs_Total_Achievable,
          All_Jobs_Total_Actual: rawData.All_Jobs_Total_Actual,
          All_Jobs_Total_Estimated: rawData.All_Jobs_Total_Estimated,
          Women_Jobs_Mean_Achievable: rawData.Female_Jobs_Mean_Achievable,
          Women_Jobs_Mean_Actual: rawData.Female_Jobs_Mean_Actual,
          Women_Jobs_Mean_Estimate: rawData.Female_Jobs_Mean_Estimate,
          Women_Jobs_Total_Achievable: rawData.Female_Jobs_Total_Achievable,
          Women_Jobs_Total_Actual: rawData.Female_Jobs_Total_Actual,
          Women_Jobs_Total_Estimated: rawData.Female_Jobs_Total_Estimated,
          LGBTQ_Jobs_Mean_Achievable: rawData.LGBTQ_Jobs_Mean_Achievable,
          LGBTQ_Jobs_Mean_Actual: rawData.LGBTQ_Jobs_Mean_Actual,
          LGBTQ_Jobs_Mean_Estimated: rawData.LGBTQ_Jobs_Mean_Estimated,
          LGBTQ_Jobs_Total_Achievable: rawData.LGBTQ_Jobs_Total_Achievable,
          LGBTQ_Jobs_Total_Actual: rawData.LGBTQ_Jobs_Total_Actual,
          LGBTQ_Jobs_Total_Estimated: rawData.LGBTQ_Jobs_Total_Estimated,

          // Industry Data
          Industry: LoadIndustryObjects(rawData.Industry),
          
          // Get policies 
          Policies: LoadPolicyObjects(rawData.DisplayName, "metro", rawData.Policy),
        },
      });
    });

    // arbitrary but consistant sort order
    newDataArray = Object.values(newDataObject).sort(function (x, y) {
      return Number(x.geo_code) - Number(y.geo_code);
    });

    return {
      arr: newDataArray,
      obj: newDataObject,
    };
  },
});

/**
 * Get state data
 */

export const stateDataQuery = selector({
  key: "stateDataQuery",
  get: async ({ get }) => {
    const response = await fetch(
      "https://storage.googleapis.com/index-frontend-data/PRODUCTION/states.json"
    );

    const jsonData = await response.json();
    const data = jsonData.data;
    //console.log("States Loaded: " + Object.values(data).length);

    let newDataArray = [];
    let newDataObject = {};

    // itterate over each state, change some variable names, fill in some missing data, and then
    // store away in both Object and Array shapes for later access
    Object.values(data)
      //.filter((data) => data.Country == "USA")
      .forEach((rawData) => {
        newDataObject = merge(newDataObject, {
          [rawData.Geo_Name]: {
            ...rawData,
            level: "state",
            geo_code: rawData.Geo_Name,
            city: rawData.DisplayName || "N/A",
            longitude: rawData.Geo_Longitude,
            latitude: rawData.Geo_Latitude,
            
          // Index Score
          All_Index_Score_Mean_Actual: rawData.All_Index_Score_Mean_Actual,
          Women_Index_Score_Mean_Actual: rawData.Female_Index_Score_Mean_Actual,
          LGBTQ_Index_Score_Mean_Actual: rawData.LGBTQ_Index_Score_Mean_Actual,
          All_Index_Score_Total_Actual: rawData.All_Index_Score_Total_Actual,
          Women_Index_Score_Total_Actual: rawData.Female_Index_Score_Total_Actual,
          LGBTQ_Index_Score_Total_Actual: rawData.LGBTQ_Index_Score_Total_Actual,
          
          // Low Stat Power
          LGBTQ_LowStatisticalPower: rawData.LGBTQ_Low_Statistical_Power,
          Women_LowStatisticalPower: rawData.Female_Low_Statistical_Power,
          All_LowStatisticalPower: false,

          // No Data power
          All_No_Statistical_Data: rawData.All_Lacking_Ent_Subfactor_Data,
          Women_No_Statistical_Data: rawData.Female_Lacking_Ent_Subfactor_Data,
          LGBTQ_No_Statistical_Data: rawData.LGBTQ_Lacking_Ent_Subfactor_Data,

          // Funding
          All_Funding_Mean_Achievable: rawData.All_Funding_Mean_Achievable,
          All_Funding_Mean_Actual: rawData.All_Funding_Mean_Actual,
          All_Funding_Mean_Estimated: rawData.All_Funding_Mean_Estimated,
          All_Funding_Total_Achievable: rawData.All_Funding_Total_Achievable,
          All_Funding_Total_Actual: rawData.All_Funding_Total_Actual,
          All_Funding_Total_Estimated: rawData.All_Funding_Total_Estimated,
          Women_Funding_Mean_Achievable: rawData.Female_Funding_Mean_Achievable,
          Women_Funding_Mean_Actual: rawData.Female_Funding_Mean_Actual,
          Women_Funding_Mean_Estimated: rawData.Female_Funding_Mean_Estimated,
          Women_Funding_Total_Achievable: rawData.Female_Funding_Total_Achievable,
          Women_Funding_Total_Actual: rawData.Female_Funding_Total_Actual,
          Women_Funding_Total_Estimated: rawData.Female_Funding_Total_Estimated,
          LGBTQ_Funding_Mean_Achievable: rawData.LGBTQ_Funding_Mean_Achievable,
          LGBTQ_Funding_Mean_Actual: rawData.LGBTQ_Funding_Mean_Actual,
          LGBTQ_Funding_Mean_Estimated: rawData.LGBTQ_Funding_Mean_Estimated,
          LGBTQ_Funding_Total_Achievable: rawData.LGBTQ_Funding_Total_Achievable,
          LGBTQ_Funding_Total_Actual: rawData.LGBTQ_Funding_Total_Actual,
          LGBTQ_Funding_Total_Estimated: rawData.LGBTQ_Funding_Total_Estimated,

          // Entrepreneur Proportion
          All_Ent_Proportion_Actual:rawData.All_Ent_Proportion_Actual,
          All_Ent_Proportion_Estimated:rawData.All_Ent_Proportion_Estimated,
          All_Ent_Proportion_Achievable:rawData.All_Ent_Proportion_Achievable,
          LGBTQ_Ent_Proportion_Actual:rawData.LGBTQ_Ent_Proportion_Actual,
          LGBTQ_Ent_Proportion_Estimated:rawData.LGBTQ_Ent_Proportion_Estimated,
          LGBTQ_Ent_Proportion_Achievable:rawData.LGBTQ_Ent_Proportion_Achievable,
          Women_Ent_Proportion_Actual:rawData.Female_Ent_Proportion_Actual,
          Women_Ent_Proportion_Estimated:rawData.Female_Ent_Proportion_Estimated,
          Women_Ent_Proportion_Achievable:rawData.Female_Ent_Proportion_Achievable,

          // Impact Score
          All_ImpactScale_Mean_Achievable: rawData.All_ImpactScale_Mean_Achievable,
          All_ImpactScale_Mean_Actual: rawData.All_ImpactScale_Mean_Actual,
          All_ImpactScale_Mean_Estimated: rawData.All_ImpactScale_Mean_Estimated,
          All_ImpactScale_Total_Achievable: rawData.All_ImpactScale_Total_Achievable,
          All_ImpactScale_Total_Actual: rawData.All_ImpactScale_Total_Actual,
          All_ImpactScale_Total_Estimated: rawData.All_ImpactScale_Total_Estimated,
          Women_ImpactScale_Mean_Achievable: rawData.Female_ImpactScale_Mean_Achievable,
          Women_ImpactScale_Mean_Actual: rawData.Female_ImpactScale_Mean_Actual,
          Women_ImpactScale_Mean_Estimated: rawData.Female_ImpactScale_Mean_Estimated,
          Women_ImpactScale_Total_Achievable: rawData.Female_ImpactScale_Total_Achievable,
          Women_ImpactScale_Total_Actual: rawData.Female_ImpactScale_Total_Actual,
          Women_ImpactScale_Total_Estimated: rawData.Female_ImpactScale_Total_Estimated,
          LGBTQ_ImpactScale_Mean_Actual: rawData.LGBTQ_ImpactScale_Mean_Actual,
          LGBTQ_ImpactScale_Mean_Estimated: rawData.LGBTQ_ImpactScale_Mean_Estimated,
          LGBTQ_ImpactScale_Mean_Achievable: rawData.LGBTQ_ImpactScale_Mean_Achievable,
          LGBTQ_ImpactScale_Total_Achievable: rawData.LGBTQ_ImpactScale_Total_Achievable,
          LGBTQ_ImpactScale_Total_Actual: rawData.LGBTQ_ImpactScale_Total_Actual,
          LGBTQ_ImpactScale_Total_Estimated: rawData.LGBTQ_ImpactScale_Total_Estimated, 

          GDP: rawData.GDP,
          GDP_Per_Capita: rawData.GDP_Per_Capita,
          Population: rawData.Population,

          // Population
          All_Ent_Pop_Actual: rawData.All_Ent_Pop_Actual,
          All_Ent_Pop_Estimated: rawData.All_Ent_Pop_Estimated,
          All_Ent_Pop_Achievable: rawData.All_Ent_Pop_Achievable,
          Women_Ent_Pop_Actual: rawData.Female_Ent_Pop_Actual,
          Women_Ent_Pop_Estimated: rawData.Female_Ent_Pop_Estimated,
          Women_Ent_Pop_Achievable: rawData.Female_Ent_Pop_Achievable,
          LGBTQ_Ent_Pop_Actual: rawData.LGBTQ_Ent_Pop_Actual,
          LGBTQ_Ent_Pop_Estimated: rawData.LGBTQ_Ent_Pop_Estimated,
          LGBTQ_Ent_Pop_Achievable: rawData.LGBTQ_Ent_Pop_Achievable,

          // Exit Counts
          All_ExitCounts_Mean_Achievable: rawData.All_ExitCounts_Mean_Achievable,
          All_ExitCounts_Mean_Actual: rawData.All_ExitCounts_Mean_Actual,
          All_ExitCounts_Mean_Estimated: rawData.All_ExitCounts_Mean_Estimated,
          All_ExitCounts_Total_Achievable: rawData.All_ExitCounts_Total_Achievable,
          All_ExitCounts_Total_Actual: rawData.All_ExitCounts_Total_Actual,
          All_ExitCounts_Total_Estimated: rawData.All_ExitCounts_Total_Estimated,
          Women_ExitCounts_Mean_Achievable: rawData.Female_ExitCounts_Mean_Achievable,
          Women_ExitCounts_Mean_Actual: rawData.Female_ExitCounts_Mean_Actual,
          Women_ExitCounts_Mean_Estimated: rawData.Female_ExitCounts_Mean_Estimated,
          Women_ExitCounts_Total_Achievable: rawData.Female_ExitCounts_Total_Achievable,
          Women_ExitCounts_Total_Actual: rawData.Female_ExitCounts_Total_Actual,
          Women_ExitCounts_Total_Estimated: rawData.Female_ExitCounts_Total_Estimated,
          LGBTQ_ExitCounts_Mean_Achievable: rawData.LGBTQ_ExitCounts_Mean_Achievable,
          LGBTQ_ExitCounts_Mean_Actual: rawData.LGBTQ_ExitCounts_Mean_Actual,
          LGBTQ_ExitCounts_Mean_Estimated: rawData.LGBTQ_ExitCounts_Mean_Estimated,
          LGBTQ_ExitCounts_Total_Achievable: rawData.LGBTQ_ExitCounts_Total_Achievable,
          LGBTQ_ExitCounts_Total_Actual: rawData.LGBTQ_ExitCounts_Total_Actual,
          LGBTQ_ExitCounts_Total_Estimated: rawData.LGBTQ_ExitCounts_Total_Estimated,

          // Patents
          All_Patents_Mean_Achievable: rawData.All_Patents_Mean_Achievable,
          All_Patents_Mean_Actual: rawData.All_Patents_Mean_Actual,
          All_Patents_Mean_Estimated: rawData.All_Patents_Mean_Estimated,
          All_Patents_Total_Achievable: rawData.All_Patents_Total_Achievable,
          All_Patents_Total_Actual: rawData.All_Patents_Total_Actual,
          All_Patents_Total_Estimated: rawData.All_Patents_Total_Estimated,
          Women_Patents_Mean_Achievable: rawData.Female_Patents_Mean_Achievable,
          Women_Patents_Mean_Actual: rawData.Female_Patents_Mean_Actual,
          Women_Patents_Mean_Estimated: rawData.Female_Patents_Mean_Estimated,
          Women_Patents_Total_Achievable: rawData.Female_Patents_Total_Achievable,
          Women_Patents_Total_Actual: rawData.Female_Patents_Total_Actual,
          Women_Patents_Total_Estimated: rawData.Female_Patents_Total_Estimated,
          LGBTQ_Patents_Mean_Achievable: rawData.LGBTQ_Patents_Mean_Achievable,
          LGBTQ_Patents_Mean_Actual: rawData.LGBTQ_Patents_Mean_Actual,
          LGBTQ_Patents_Mean_Estimated: rawData.LGBTQ_Patents_Mean_Estimated,
          LGBTQ_Patents_Total_Achievable: rawData.LGBTQ_Patents_Total_Achievable,
          LGBTQ_Patents_Total_Actual: rawData.LGBTQ_Patents_Total_Actual,
          LGBTQ_Patents_Total_Estimated: rawData.LGBTQ_Patents_Total_Estimated,
          
          // Job Counts
          All_Jobs_Mean_Achievable: rawData.All_Jobs_Mean_Achievable,
          All_Jobs_Mean_Actual: rawData.All_Jobs_Mean_Actual,
          All_Jobs_Mean_Estimated: rawData.All_Jobs_Mean_Estimated,
          All_Jobs_Total_Achievable: rawData.All_Jobs_Total_Achievable,
          All_Jobs_Total_Actual: rawData.All_Jobs_Total_Actual,
          All_Jobs_Total_Estimated: rawData.All_Jobs_Total_Estimated,
          Women_Jobs_Mean_Achievable: rawData.Female_Jobs_Mean_Achievable,
          Women_Jobs_Mean_Actual: rawData.Female_Jobs_Mean_Actual,
          Women_Jobs_Mean_Estimate: rawData.Female_Jobs_Mean_Estimate,
          Women_Jobs_Total_Achievable: rawData.Female_Jobs_Total_Achievable,
          Women_Jobs_Total_Actual: rawData.Female_Jobs_Total_Actual,
          Women_Jobs_Total_Estimated: rawData.Female_Jobs_Total_Estimated,
          LGBTQ_Jobs_Mean_Achievable: rawData.LGBTQ_Jobs_Mean_Achievable,
          LGBTQ_Jobs_Mean_Actual: rawData.LGBTQ_Jobs_Mean_Actual,
          LGBTQ_Jobs_Mean_Estimated: rawData.LGBTQ_Jobs_Mean_Estimated,
          LGBTQ_Jobs_Total_Achievable: rawData.LGBTQ_Jobs_Total_Achievable,
          LGBTQ_Jobs_Total_Actual: rawData.LGBTQ_Jobs_Total_Actual,
          LGBTQ_Jobs_Total_Estimated: rawData.LGBTQ_Jobs_Total_Estimated,

          // Industry Data
          Industry: LoadIndustryObjects(rawData.Industry),

          // Get policies
          Policies: LoadPolicyObjects(rawData.DisplayName, "state", rawData.Policy),
          },
        });
      });

    // arbitrary but consistant sort order
    newDataArray = Object.values(newDataObject).sort(function (x, y) {
      return Number(x.geo_code) - Number(y.geo_code);
    });
    return {
      arr: newDataArray,
      obj: newDataObject,
    };
  },
});

/**
 * Get country data
 */
export const countryDataQuery = selector({
  key: "countryDataQuery",
  get: async ({ get }) => {
    const response = await fetch(
      "https://storage.googleapis.com/index-frontend-data/PRODUCTION/countries.json"
    );

    const jsonData = await response.json();
    const data = jsonData.data;
    //console.log("Countries Loaded: " + Object.values(data).length);

    let newDataArray = [];
    let newDataObject = {};

    Object.values(data).forEach((rawData) => {
      newDataObject = merge(newDataObject, {
        [rawData.Geo_Name]: {
          ...rawData,
            level: "country",
            geo_code: rawData.Geo_Name,
            city: rawData.DisplayName || "N/A",
            longitude: rawData.Geo_Longitude,
            latitude: rawData.Geo_Latitude,

          // Index Score
          All_Index_Score_Mean_Actual: rawData.All_Index_Score_Mean_Actual,
          Women_Index_Score_Mean_Actual: rawData.Female_Index_Score_Mean_Actual,
          LGBTQ_Index_Score_Mean_Actual: rawData.LGBTQ_Index_Score_Mean_Actual,
          All_Index_Score_Total_Actual: rawData.All_Index_Score_Total_Actual,
          Women_Index_Score_Total_Actual: rawData.Female_Index_Score_Total_Actual,
          LGBTQ_Index_Score_Total_Actual: rawData.LGBTQ_Index_Score_Total_Actual,
          
          // Low Stat Power
          LGBTQ_LowStatisticalPower: rawData.LGBTQ_Low_Statistical_Power,
          Women_LowStatisticalPower: rawData.Female_Low_Statistical_Power,
          All_LowStatisticalPower: false,

          // No Data power
          All_No_Statistical_Data: rawData.All_Lacking_Ent_Subfactor_Data,
          Women_No_Statistical_Data: rawData.Female_Lacking_Ent_Subfactor_Data,
          LGBTQ_No_Statistical_Data: rawData.LGBTQ_Lacking_Ent_Subfactor_Data,

          // Funding
          All_Funding_Mean_Achievable: rawData.All_Funding_Mean_Achievable,
          All_Funding_Mean_Actual: rawData.All_Funding_Mean_Actual,
          All_Funding_Mean_Estimated: rawData.All_Funding_Mean_Estimated,
          All_Funding_Total_Achievable: rawData.All_Funding_Total_Achievable,
          All_Funding_Total_Actual: rawData.All_Funding_Total_Actual,
          All_Funding_Total_Estimated: rawData.All_Funding_Total_Estimated,
          Women_Funding_Mean_Achievable: rawData.Female_Funding_Mean_Achievable,
          Women_Funding_Mean_Actual: rawData.Female_Funding_Mean_Actual,
          Women_Funding_Mean_Estimated: rawData.Female_Funding_Mean_Estimated,
          Women_Funding_Total_Achievable: rawData.Female_Funding_Total_Achievable,
          Women_Funding_Total_Actual: rawData.Female_Funding_Total_Actual,
          Women_Funding_Total_Estimated: rawData.Female_Funding_Total_Estimated,
          LGBTQ_Funding_Mean_Achievable: rawData.LGBTQ_Funding_Mean_Achievable,
          LGBTQ_Funding_Mean_Actual: rawData.LGBTQ_Funding_Mean_Actual,
          LGBTQ_Funding_Mean_Estimated: rawData.LGBTQ_Funding_Mean_Estimated,
          LGBTQ_Funding_Total_Achievable: rawData.LGBTQ_Funding_Total_Achievable,
          LGBTQ_Funding_Total_Actual: rawData.LGBTQ_Funding_Total_Actual,
          LGBTQ_Funding_Total_Estimated: rawData.LGBTQ_Funding_Total_Estimated,

          // Entrepreneur Proportion
          All_Ent_Proportion_Actual:rawData.All_Ent_Proportion_Actual,
          All_Ent_Proportion_Estimated:rawData.All_Ent_Proportion_Estimated,
          All_Ent_Proportion_Achievable:rawData.All_Ent_Proportion_Achievable,
          LGBTQ_Ent_Proportion_Actual:rawData.LGBTQ_Ent_Proportion_Actual,
          LGBTQ_Ent_Proportion_Estimated:rawData.LGBTQ_Ent_Proportion_Estimated,
          LGBTQ_Ent_Proportion_Achievable:rawData.LGBTQ_Ent_Proportion_Achievable,
          Women_Ent_Proportion_Actual:rawData.Female_Ent_Proportion_Actual,
          Women_Ent_Proportion_Estimated:rawData.Female_Ent_Proportion_Estimated,
          Women_Ent_Proportion_Achievable:rawData.Female_Ent_Proportion_Achievable,

          // Impact Score
          All_ImpactScale_Mean_Achievable: rawData.All_ImpactScale_Mean_Achievable,
          All_ImpactScale_Mean_Actual: rawData.All_ImpactScale_Mean_Actual,
          All_ImpactScale_Mean_Estimated: rawData.All_ImpactScale_Mean_Estimated,
          All_ImpactScale_Total_Achievable: rawData.All_ImpactScale_Total_Achievable,
          All_ImpactScale_Total_Actual: rawData.All_ImpactScale_Total_Actual,
          All_ImpactScale_Total_Estimated: rawData.All_ImpactScale_Total_Estimated,
          Women_ImpactScale_Mean_Achievable: rawData.Female_ImpactScale_Mean_Achievable,
          Women_ImpactScale_Mean_Actual: rawData.Female_ImpactScale_Mean_Actual,
          Women_ImpactScale_Mean_Estimated: rawData.Female_ImpactScale_Mean_Estimated,
          Women_ImpactScale_Total_Achievable: rawData.Female_ImpactScale_Total_Achievable,
          Women_ImpactScale_Total_Actual: rawData.Female_ImpactScale_Total_Actual,
          Women_ImpactScale_Total_Estimated: rawData.Female_ImpactScale_Total_Estimated,
          LGBTQ_ImpactScale_Mean_Actual: rawData.LGBTQ_ImpactScale_Mean_Actual,
          LGBTQ_ImpactScale_Mean_Estimated: rawData.LGBTQ_ImpactScale_Mean_Estimated,
          LGBTQ_ImpactScale_Mean_Achievable: rawData.LGBTQ_ImpactScale_Mean_Achievable,
          LGBTQ_ImpactScale_Total_Achievable: rawData.LGBTQ_ImpactScale_Total_Achievable,
          LGBTQ_ImpactScale_Total_Actual: rawData.LGBTQ_ImpactScale_Total_Actual,
          LGBTQ_ImpactScale_Total_Estimated: rawData.LGBTQ_ImpactScale_Total_Estimated, 

          GDP: rawData.GDP,
          GDP_Per_Capita: rawData.GDP_Per_Capita,
          Population: rawData.Population,

          // Population
          All_Ent_Pop_Actual: rawData.All_Ent_Pop_Actual,
          All_Ent_Pop_Estimated: rawData.All_Ent_Pop_Estimated,
          All_Ent_Pop_Achievable: rawData.All_Ent_Pop_Achievable,
          Women_Ent_Pop_Actual: rawData.Female_Ent_Pop_Actual,
          Women_Ent_Pop_Estimated: rawData.Female_Ent_Pop_Estimated,
          Women_Ent_Pop_Achievable: rawData.Female_Ent_Pop_Achievable,
          LGBTQ_Ent_Pop_Actual: rawData.LGBTQ_Ent_Pop_Actual,
          LGBTQ_Ent_Pop_Estimated: rawData.LGBTQ_Ent_Pop_Estimated,
          LGBTQ_Ent_Pop_Achievable: rawData.LGBTQ_Ent_Pop_Achievable,

          // Exit Counts
          All_ExitCounts_Mean_Achievable: rawData.All_ExitCounts_Mean_Achievable,
          All_ExitCounts_Mean_Actual: rawData.All_ExitCounts_Mean_Actual,
          All_ExitCounts_Mean_Estimated: rawData.All_ExitCounts_Mean_Estimated,
          All_ExitCounts_Total_Achievable: rawData.All_ExitCounts_Total_Achievable,
          All_ExitCounts_Total_Actual: rawData.All_ExitCounts_Total_Actual,
          All_ExitCounts_Total_Estimated: rawData.All_ExitCounts_Total_Estimated,
          Women_ExitCounts_Mean_Achievable: rawData.Female_ExitCounts_Mean_Achievable,
          Women_ExitCounts_Mean_Actual: rawData.Female_ExitCounts_Mean_Actual,
          Women_ExitCounts_Mean_Estimated: rawData.Female_ExitCounts_Mean_Estimated,
          Women_ExitCounts_Total_Achievable: rawData.Female_ExitCounts_Total_Achievable,
          Women_ExitCounts_Total_Actual: rawData.Female_ExitCounts_Total_Actual,
          Women_ExitCounts_Total_Estimated: rawData.Female_ExitCounts_Total_Estimated,
          LGBTQ_ExitCounts_Mean_Achievable: rawData.LGBTQ_ExitCounts_Mean_Achievable,
          LGBTQ_ExitCounts_Mean_Actual: rawData.LGBTQ_ExitCounts_Mean_Actual,
          LGBTQ_ExitCounts_Mean_Estimated: rawData.LGBTQ_ExitCounts_Mean_Estimated,
          LGBTQ_ExitCounts_Total_Achievable: rawData.LGBTQ_ExitCounts_Total_Achievable,
          LGBTQ_ExitCounts_Total_Actual: rawData.LGBTQ_ExitCounts_Total_Actual,
          LGBTQ_ExitCounts_Total_Estimated: rawData.LGBTQ_ExitCounts_Total_Estimated,

          // Patents
          All_Patents_Mean_Achievable: rawData.All_Patents_Mean_Achievable,
          All_Patents_Mean_Actual: rawData.All_Patents_Mean_Actual,
          All_Patents_Mean_Estimated: rawData.All_Patents_Mean_Estimated,
          All_Patents_Total_Achievable: rawData.All_Patents_Total_Achievable,
          All_Patents_Total_Actual: rawData.All_Patents_Total_Actual,
          All_Patents_Total_Estimated: rawData.All_Patents_Total_Estimated,
          Women_Patents_Mean_Achievable: rawData.Female_Patents_Mean_Achievable,
          Women_Patents_Mean_Actual: rawData.Female_Patents_Mean_Actual,
          Women_Patents_Mean_Estimated: rawData.Female_Patents_Mean_Estimated,
          Women_Patents_Total_Achievable: rawData.Female_Patents_Total_Achievable,
          Women_Patents_Total_Actual: rawData.Female_Patents_Total_Actual,
          Women_Patents_Total_Estimated: rawData.Female_Patents_Total_Estimated,
          LGBTQ_Patents_Mean_Achievable: rawData.LGBTQ_Patents_Mean_Achievable,
          LGBTQ_Patents_Mean_Actual: rawData.LGBTQ_Patents_Mean_Actual,
          LGBTQ_Patents_Mean_Estimated: rawData.LGBTQ_Patents_Mean_Estimated,
          LGBTQ_Patents_Total_Achievable: rawData.LGBTQ_Patents_Total_Achievable,
          LGBTQ_Patents_Total_Actual: rawData.LGBTQ_Patents_Total_Actual,
          LGBTQ_Patents_Total_Estimated: rawData.LGBTQ_Patents_Total_Estimated,
          
          // Job Counts
          All_Jobs_Mean_Achievable: rawData.All_Jobs_Mean_Achievable,
          All_Jobs_Mean_Actual: rawData.All_Jobs_Mean_Actual,
          All_Jobs_Mean_Estimated: rawData.All_Jobs_Mean_Estimated,
          All_Jobs_Total_Achievable: rawData.All_Jobs_Total_Achievable,
          All_Jobs_Total_Actual: rawData.All_Jobs_Total_Actual,
          All_Jobs_Total_Estimated: rawData.All_Jobs_Total_Estimated,
          Women_Jobs_Mean_Achievable: rawData.Female_Jobs_Mean_Achievable,
          Women_Jobs_Mean_Actual: rawData.Female_Jobs_Mean_Actual,
          Women_Jobs_Mean_Estimate: rawData.Female_Jobs_Mean_Estimate,
          Women_Jobs_Total_Achievable: rawData.Female_Jobs_Total_Achievable,
          Women_Jobs_Total_Actual: rawData.Female_Jobs_Total_Actual,
          Women_Jobs_Total_Estimated: rawData.Female_Jobs_Total_Estimated,
          LGBTQ_Jobs_Mean_Achievable: rawData.LGBTQ_Jobs_Mean_Achievable,
          LGBTQ_Jobs_Mean_Actual: rawData.LGBTQ_Jobs_Mean_Actual,
          LGBTQ_Jobs_Mean_Estimated: rawData.LGBTQ_Jobs_Mean_Estimated,
          LGBTQ_Jobs_Total_Achievable: rawData.LGBTQ_Jobs_Total_Achievable,
          LGBTQ_Jobs_Total_Actual: rawData.LGBTQ_Jobs_Total_Actual,
          LGBTQ_Jobs_Total_Estimated: rawData.LGBTQ_Jobs_Total_Estimated,

          // Industry Data
          Industry: LoadIndustryObjects(rawData.Industry),
          
          // Get policies by group
          Policies: LoadPolicyObjects(rawData.DisplayName, "state", rawData.Policy),
        },
      });
    });

    newDataArray = Object.values(newDataObject);
    return {
      arr: newDataArray,
      obj: newDataObject,
    };
  },
});

function LoadIndustryObjects(industries) {
  var IndustryList = []; // Array to hold the industry objects per geographic area

  // Loop through the industry objects
  for(var indus in industries) {
    var temp = industries[indus];

    let industry = {
      Industry: temp["Industry_Full"],
      IndustryAbbreviated: temp["Industry"],
      IndustrySector: temp["Industry_Sector"],
      All_Count: temp["All_Count"],
      All_Mean_Index_Score: temp["All_Mean_Index_Score"],
      All_Total_Index_Score: temp["All_Total_Index_Score"],
      Women_Count: temp["Female_Count"],
      Women_Mean_Index_Score: temp["Female_Mean_Index_Score"],
      Women_Total_Index_Score: temp["Female_Total_Index_Score"],
      LGBTQ_Count: temp["LGBTQ_Count"],
      LGBTQ_Mean_Index_Score: temp["LGBTQ_Mean_Index_Score"],
      LGBTQ_Total_Index_Score: temp["LGBTQ_Total_Index_Score"],
      Likelihood: temp["Likelihood_Ratio"],
      CompositeScore: temp["Composite_Score_Count"],
      RelativeProportion: temp["Relative_Proportion"]
    };

    IndustryList.push(industry);
  }

  return IndustryList;
}

function LoadPolicyObjects(geo, geolevel, policies) {
  var PolicyList = []; // Array to hold the policy objects per geographic area
  var count = 0;

  // Loop through the policy objects
  for(var polic in policies) {
    var temp = policies[polic];

      let policy = {
        target_actual: temp["target_actual"],
        target_predicted: temp["target_predicted"],
        target_intervention: temp["target_intervention"],
        policy: temp["policy"],
        policy_full: temp["policy_full"],
        description : temp["description"],
        response_type: temp["response_type"],
        //target_view: temp["target_view"].substring(0,1).toUpperCase() + temp["target_view"].substring(1),
        //target_view_lower: temp["target_view"],
        target_factor: temp["target_factor"],
        target_group: temp["target_group"],
        target_category: temp["target_category"],
        source : temp["source"],
        source_url : temp["source_url"],
        display_type: temp["display_type"],
        confidence_percent: temp["confidence_percent"],
        confidence_high: temp["confidence_high"],
        confidence_low: temp["confidence_low"],
        confidence_level: temp["confidence_level"],
        duration: temp["duration"],
        peer: temp["peer"],
        geolevel:geolevel,
        geo: geo,
        num:count,
        dataPoints: 
          [{
            x: 0,
            y: temp["target_actual"],
            name: "Actual",
            type: temp["target_factor"],
            color: "rgb(13, 49, 107)",
          },
           {
            x: 1,
            y: temp["target_predicted"],
            name: "Predicted",
            type: temp["target_factor"],
            color: "#ad8961",
          },]
        ,
      };

      count = count + 1; // Used to differentiate policies in same geographic area
      PolicyList.push(policy);
  }

  return PolicyList;
}

/* 
* Gets the top policy for current level for each category.
*/
function GetSuggestedPolicy(dataset) {
  var policy = [];
  var counter = Math.floor(Math.random()*100)+1; // Used to randomly determine which state to select

  // If there is no policy, take the first one in the list
  if(dataset.Policies === undefined || dataset.Policies === 0) {
    for (var i = 0; i < dataset.length; i++) {
      for (var j = 0; j < dataset[i].Policies.length; j++) {
        if (policy === undefined || policy.length === 0) {
          policy.push(dataset[i].Policies[j]);
        }
        else if (policy[0].confidence_level < dataset[i].Policies[j].confidence_level 
          || (counter > 0 && policy[0].confidence_level === dataset[i].Policies[j].confidence_level )) {
          policy.pop();
          policy.push(dataset[i].Policies[j]);
          counter--;
        }
      }
    }
  }

  return policy;
}