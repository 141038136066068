import React from "react";
import { useRecoilValue } from "recoil";
import { selectedMetroState } from "recoil/selectors";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import InfoTip from "components/InfoTip";

const PowerSup = styled.sup`
  font-size: 1.3em !important;
`;

const LowPower = () => {
  const metro = useRecoilValue(selectedMetroState);

  return (
    <>         
      {metro.No_Statistical_Data === true ? (
        <InfoTip name="alertNoData" as="span">
          <PowerSup>
          <FontAwesomeIcon
              style={{ color: "rgb(230, 7, 7)", verticalAlign: "middle", verticalAlign: "-moz-middle-with-baseline", fontSize: "x-large" }}
              icon={faExclamationTriangle}
              />   
              &nbsp;
          </PowerSup>
        </InfoTip>
      ) : metro.low_statistical_power === true ? (
        <InfoTip name="alertLowPower" as="span">
          <PowerSup>
          <FontAwesomeIcon
              style={{ color: "rgb(230, 7, 7)", verticalAlign: "middle", verticalAlign: "-moz-middle-with-baseline", fontSize: "x-large" }}
              icon={faExclamationTriangle}
              />   
              &nbsp;
          </PowerSup>
        </InfoTip>
      ) : null}
    </>
  );
};

export default LowPower;
