import React from "react";
import { useRecoilValue } from "recoil";
import { selectedMetroNameState, selectedMetroState, selectedMetroCouldHaveJobsState } from "recoil/selectors";
import { populationState } from "recoil/atoms";
import IndexScore from "./IndexScore";
import LowPower from "./LowPower";
import styled from "styled-components";
import InfoTip from "components/InfoTip";

const StyledStart = styled.span`
  font-size: 1.4em;
  line-height: 1.3em;
`;

const MetroName = styled.span`
  font-size: 1.1em;
  font-weight: 700;
`;
const MinHeight = styled.div`
  min-height: 10em;
  padding-left:14px;
  padding-right:14px;
`;

const CouldHaveText = styled.span`
  font-size: clamp(14px, 1vw, 18px);
  line-height: clamp(21px, 1.3vw, 25px);
`;

function MetroInfo() {
  const name = useRecoilValue(selectedMetroNameState);
  const metro = useRecoilValue(selectedMetroState);
  const population = useRecoilValue(populationState);
  const n = useRecoilValue(selectedMetroCouldHaveJobsState);

  return (
    <>
      <MinHeight>
        <LowPower /> {"  "}
        <InfoTip name="couldHave" as="span">
          <CouldHaveText>
          {metro.No_Statistical_Data === true ? 
            <span> <MetroName>{name}</MetroName> lacks reported  <b> {population} </b> entrepreneur data. <a href='https://docs.google.com/forms/d/e/1FAIpQLSfVI3IdL8mk6RqCrPRFRYiqdOT0hv0RwTGJDQgK0UpmtcAZmQ/viewform' target="_blank"> Contribute to our data set.</a></span>
            : metro.low_statistical_power ?
            <span> <MetroName>{name}</MetroName> has limited reported  <b> {population} </b> entrepreneur data. <a href='https://docs.google.com/forms/d/e/1FAIpQLSfVI3IdL8mk6RqCrPRFRYiqdOT0hv0RwTGJDQgK0UpmtcAZmQ/viewform' target="_blank"> Contribute to our data set.</a></span>
            : <span> <b> {population} </b> entrepreneurs in <MetroName>{name}</MetroName> could have created <b> {n} </b> more jobs since the year 2000.</span>
          }
          </CouldHaveText>
        </InfoTip>
        <br />
        <br />
        <IndexScore />
      </MinHeight>
    </>
  );
}

export default MetroInfo;
