import React, { useEffect, Suspense, useState } from "react";
import * as d3 from "d3";
import memoize from "fast-memoize";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { activeMetrosState, metroDataSlice } from "recoil/selectors";
import { selectedMetroIDState, visState, pristineState, populationState, regionLevelState, viewState,
  chartFeatureState, plotYAxisState, plotXAxisState, hoveredMetroIDState, hoveredMetroDomState,
  yLogScaleState, xLogScaleState, chartLogScaleState } from "recoil/atoms";
import Map from "./Map";
import Plot from "./Plot";
import Chart from "./Chart";
import VisPopup from "./VisPopup";
import settings from "res/settings";
import Loading from "components/Loading";

export const bubbleSize = memoize((size) => {
  // Hard coded version
  var output = size/16;

  // Manual scoring
  if(size > 95) { output = size*14; }
  else if(size > 90) { output = size*7; }
  else if(size > 85) { output = size*6.5; }
  else if(size > 80) { output = size*6; }
  else if(size > 75) { output = size*5.5; }
  else if(size > 70) { output = size*5; }
  else if(size > 65) { output = size*4.5; }
  else if(size > 60) { output = size*4; }
  else if(size > 55) { output = size*3.5; }
  else if(size > 50) { output = size*3; }
  else if(size > 45) { output = size*2.5; }
  else if(size > 40) { output = size*2; }
  else if(size > 35) { output = size*1.5; }
  else if(size > 30) { output = size; }
  else if(size > 20) { output = size/3; }
  else if(size > 10) { output = size/5; }

  return d3.scaleSqrt()(output); 
});

export const bubbleColor = memoize((color) => {
  return d3.scaleSequential(d3.interpolateRdYlGn).domain([0, 100])(color);
});

const VisD3 = ({ d3Ref }) => {
  const allMetroData = useRecoilValue(metroDataSlice);
  const metroData = useRecoilValue(activeMetrosState);
  const [selectedMetroID, setSelectedMetroID] = useRecoilState(selectedMetroIDState);
  const vis = useRecoilValue(visState);
  const population = useRecoilValue(populationState);
  const regionLevel = useRecoilValue(regionLevelState);
  const view = useRecoilValue(viewState);
  const feature = useRecoilValue(chartFeatureState);
  const yAxisVar = useRecoilValue(plotYAxisState);
  const xAxisVar = useRecoilValue(plotXAxisState);
  const yLogScale = useRecoilValue(yLogScaleState);
  const xLogScale = useRecoilValue(xLogScaleState);
  const chartLogScale = useRecoilValue(chartLogScaleState);
  const [pristine, setPristine] = useRecoilState(pristineState);
  const [voronoiDiagram, setVoronoiDiagram] = useState(null);
  const [hoveredMetroID, setHoveredMetroID] = useRecoilState(hoveredMetroIDState);
  const setHoveredMetroDom = useSetRecoilState(hoveredMetroDomState);
  const handleMetroClick = (d, elem) => {
    setSelectedMetroID(d.geo_code);
    setHoveredMetroID(null);
    setHoveredMetroDom(null);
  };

  const handleOver = (d, elem) => {
    setHoveredMetroID(d.geo_code);
    setHoveredMetroDom(elem);
  };

  const handleOut = (d, elem) => {
    setHoveredMetroID(null);
    setHoveredMetroDom(null);
  };

  // Variables used for finding nearest geographic point to the mouse
  /*let triangles;
  let data = []; // Used to hold the coordinates/geo ids on the map

  function update() {
    d3.select('svg')
      .selectAll('circle')
      .data(data)
      .join('circle')
      .attr('cx', function(d) { return d.x; })
      .attr('cy', function(d) { return d.y; })
      .attr('r', function(d) { return d.r; })
      .style('fill', function(d) { return d.id === hoveredMetroID ? 'black' : null;});
  }

  //function UpdatePointData(geoData) {
    data = [];
    for(let i=0; i<allMetroData.length; i++) {
      data.push({
        id: i,
        x: allMetroData[i].longitude,
        y: allMetroData[i].latitude,
        r: allMetroData[i].Geo_Name
      });
    }
  //

  //UpdatePointData();*/

  useEffect(() => {
    d3.selectAll(".bubble")
      .style("stroke", "black")
      .style("opacity", 0.9)
      .style("stroke-width", vis === "chart" ? 1 : 1);

    d3.selectAll(`.chart-line`).attr("stroke", (d) => d.chart_color);

    selectedMetroID &&
      d3
        .select(`#metro${selectedMetroID}`)
        .style("stroke", "black")
        .style("opacity", 1)
        .style("stroke-width", vis === "chart" ? 2 : 2);

    selectedMetroID &&
      d3.select(`.chart-line-${selectedMetroID}`).attr("stroke", "black");

    hoveredMetroID &&
      d3
        .select(`#metro${hoveredMetroID}`)
        .style("stroke", "black")
        .style("opacity", 1)
        .style("stroke-width", vis === "chart" ? 2 : 2);

    hoveredMetroID &&
      d3.select(`.chart-line-${hoveredMetroID}`).attr("stroke", "black");

    return () => {};
  }, [selectedMetroID, hoveredMetroID, vis]);

  useEffect(() => {
    if (metroData && d3Ref.current && pristine) {
      const svg = d3.select(d3Ref.current);

      svg
        .append("circle")
        .attr("class", "highlight-circle")
        .attr("r", 5) // slightly larger than our points
        .style("fill", "none")
        .style("display", "none");

      const background = svg
        .append("rect")
        .attr("id", "mouse-catcher")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", settings.vis.svg.dims.width)
        .attr("height", settings.vis.svg.dims.height)
        .style("opacity", 0);

      background.on("click", function (d) {
        setSelectedMetroID(0);
        setHoveredMetroID(null);
        setHoveredMetroDom(null);
      });

      // Bind D3 data
      const update = svg
        .append("g")
        .attr("id", "svg-container")
        .selectAll("rect.bubble")
        .data(metroData, function (d, i) {
          return d.geo_code;
        });

      // Enter new D3 elements

      update
        .enter()
        .append("rect")
        .attr("id", function (d) {
          return `metro${d.geo_code}`;
        })
        .attr("x", (d) => d[`${vis}_x`])
        .attr("y", (d) => d[`${vis}_y`])
        .attr("rx", (d) => d[`${vis}_rx`])
        .attr("ry", (d) => d[`${vis}_ry`])
        .attr("width", (d) => d[`${vis}_width`])
        .attr("height", (d) => d[`${vis}_height`])
        .attr("fill", (d) => d[`${vis}_color`])
        .attr("class", "bubble")
        .style("opacity", 1)
        .style("stroke", "black")
        .style("stroke-width", 1)
        .on("click", (d) => {
          handleMetroClick(d, this);
        });

      // Update existing D3 elements
      svg.selectAll("rect.bubble").sort((a, b) => {
        return d3.descending(a.bubble_size, b.bubble_size);
      });

      setPristine(false);
    }
  }, [d3Ref]);

  // update based on ui changes
  useEffect(() => {
    if (!pristine) {
      const svg = d3.select(d3Ref.current);

      const update = svg
        .selectAll("rect.bubble")
        .data(metroData, function (d, i) {
          return d.geo_code;
        });

      update
        .enter()
        .append("rect")
        .attr("id", function (d) {
          return `metro${d.geo_code}`;
        })
        .attr("x", (d) => d[`${vis}_x`])
        .attr("y", (d) => d[`${vis}_y`])
        .attr("rx", (d) => d[`${vis}_rx`])
        .attr("ry", (d) => d[`${vis}_ry`])
        .attr("width", (d) => d[`${vis}_width`])
        .attr("height", (d) => d[`${vis}_height`])
        .attr("fill", (d) => d[`${vis}_color`])
        .attr("class", "bubble")
        .style("opacity", 1)
        .style("stroke", "black")
        .style("stroke-width", 1)
        .on("click", (d) => {
          handleMetroClick(d, this);
        });

      update.exit().remove();

      svg
        .selectAll("rect.bubble")
        /*.on("mousemove", function (event) {
          let mousePos = d3.pointer(event, this);
          let i = triangles.find(mousePos[0], mousePos[1]);
          setHoveredMetroID(data[i].id);
          update();
        })*/
        .on("mouseover", function (d) {
          handleOver(d, this);
        })
        .on("mouseout", function (d) {
          handleOut(d, this);
        })
        .transition()
        .duration(1500)
        .attr("x", (d) => d[`${vis}_x`])
        .attr("y", (d) => d[`${vis}_y`])
        .attr("rx", (d) => d[`${vis}_rx`])
        .attr("ry", (d) => d[`${vis}_ry`])
        .attr("width", (d) => d[`${vis}_width`])
        .attr("height", (d) => d[`${vis}_height`])
        .attr("fill", (d) => d[`${vis}_color`])
        .style("opacity", 1);

      svg
        .selectAll(".states")
        .transition()
        .duration(1500)
        .style("opacity", vis === "map" && regionLevel !== "industry" ? 1 : 0);

    }

    return () => {};
  }, [
    vis,
    pristine,
    population,
    regionLevel,
    metroData,
    view,
    feature,
    yAxisVar,
    xAxisVar,
    yLogScale,
    xLogScale,
    chartLogScale,
  ]);

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Map svgRef={d3Ref} />
        <Plot svgRef={d3Ref} />
        <Chart svgRef={d3Ref} />
        <svg
          viewBox={`0 0 ${settings.vis.svg.dims.width} ${settings.vis.svg.dims.height}`}
          preserveAspectRatio="xMinYMin meet"
          className="d3-component main-vis-content"
          ref={d3Ref}
        />
        <VisPopup />
      </Suspense>
    </>
  );
};

export default VisD3;
