import * as d3 from "d3";

const money = { huge: "$,.3~s", large: "$,.2~s", medium: "$,.2~s", small: "$,.2~f" };
const count = { huge: ",.2~s", large: ",.2~s", medium: ",.2~s", small: ",.0~f" };
const pop = { huge: ",.3~s", large: ",.3~s", medium: ",.2~s", small: ",.0~f" };
const popExpanded = { huge: ",.3~s", large: ",.3~s", medium: ",.3~s", small: ",.3~f" };
const mean = { huge: ",.2~s", large: ",.2~s", medium: ",.2~s", small: ",.2~f" };
const decimal = { huge: ",.6~s", large: ",.6~s", medium: ",.6~s", small: ",.6~f" };
const percent = { huge: ".0%", large: ".0%", medium: ".0%", small: ".0%" };
const index = { huge: ".0f", large: ".0f", medium: ".0f", small: ".0f" };
const estimate = { color: "blue" };
const achievable = {color: "rgb(91, 55, 102)"}
const defaultFormat = ",";

export const formats = {
  index_number: index,
  gdp: money,
  population: pop,
  popExpanded: popExpanded,
  funding: money,
  funding_sub: money,
  jobs: count,
  jobs_created_sub: count,
  patents: count,
  patents_sub: count,
  exit_count: count,
  exits: percent,
  exit_value: money,
  exit_value_sub: money,
  exits_count: count,
  exits_value: money,
  entrepreneurs: count,
  all_entrepreneurs: count,
  count: count,
  money: money,
  estimate: estimate,
  achieveable: achievable,
  mean: mean,
  decimal:decimal,
};

const format = (name, val, words) => {
  var size = "small";
  if (val > 10000000 || val < -10000000) {
    size = "huge";
  } 
  else if (val > 100000 || val < -100000) {
    size = "large";
  } 
  else if (val > 10000 || val < -10000) {
    size = "medium";
  }

  const format = formats[name] && formats[name][size];
  const outputVal = d3.format(format || defaultFormat)(val);
  var workVal = outputVal;

  if(words) { 
    if(outputVal.indexOf("T") <= 0 && outputVal.indexOf("t") <= 0 
    && outputVal.indexOf("G") <= 0 && outputVal.indexOf("g") <= 0 
    && outputVal.indexOf("M") <= 0 && outputVal.indexOf("m") <= 0 
    && outputVal.indexOf("B") <= 0 && outputVal.indexOf("b") <= 0 
    && outputVal.indexOf("P") <= 0 && outputVal.indexOf("p") <= 0) {
      // If there is a k, convert to thousands
      if(outputVal.indexOf("K") > 0 || outputVal.indexOf("k") > 0) {
        if(outputVal.indexOf(".") > 0) {
          workVal = workVal.replace("k", "").replace("K", "");
          workVal = workVal.substring(0, workVal.indexOf(".")) + "," + (workVal.substring(workVal.indexOf(".")+1) + "000").substring(0, 3);
          return workVal;
        }
        
        return outputVal.replace("K", "").replace("k", "") + ",000";
      }

      if(outputVal.indexOf(".") > 0) {
        return outputVal.substring(0, outputVal.indexOf(".")) + outputVal.substring(outputVal.length-1);
      }

      return outputVal;
    }
    else if(outputVal.substring(0,1) === "$") {
      // Clip the decimal and following off
      /*if(outputVal.indexOf(".") > 0) {
        workVal = outputVal.substring(0, outputVal.indexOf(".")) + outputVal.substring(outputVal.length-1);
      }*/

      // Replace the $ and put in words for number
      if(workVal.indexOf("T") > 0 || workVal.indexOf("t") > 0) {
        return workVal.replace("t", " trillion").replace("T", " trillion");
      }
      else if(workVal.indexOf("G") > 0 || workVal.indexOf("g") > 0) {
        return workVal.replace("G", " billion").replace("g", " billion");
      }
      else if(workVal.indexOf("K") > 0 || workVal.indexOf("k") > 0) {
        return workVal.replace("K", " thousand").replace("k", " thousand");
      }
      else if(workVal.indexOf("M") > 0 || workVal.indexOf("m") > 0) {
        return workVal.replace("m", " million").replace("M", " million");
      }
      else if(workVal.indexOf("P") > 0 || workVal.indexOf("p") > 0) {
        return workVal.replace("P", " quadrillion").replace("p", " quadrillion");
      }

      return workVal + " dollars";
    }
    else {
      // Clip the decimal and following off
      workVal = outputVal;

      if(workVal.indexOf("T") > 0 || workVal.indexOf("t") > 0) {
        return workVal.replace("t", " trillion").replace("T", " trillion");
      }
      else if(workVal.indexOf("G") > 0 || workVal.indexOf("g") > 0) {
        return workVal.replace("G", " billion").replace("g", " billion");
      }
      else if(workVal.indexOf("K") > 0 || workVal.indexOf("k") > 0) {
        return workVal.replace("K", " thousand").replace("k", " thousand");
      }
      else if(workVal.indexOf("M") > 0 || workVal.indexOf("m") > 0) {
        return workVal.replace("m", " million").replace("M", " million");
      }
      else if(workVal.indexOf("P") > 0 || workVal.indexOf("p") > 0) {
        return workVal.replace("P", " quadrillion").replace("p", " quadrillion");
      }

      return workVal;
    }
  }
  else {
    return outputVal.replace("G", "B").replace("k", "K");
  }
};

export default format;
