import React, { useRef, useEffect }  from "react";
import { useRecoilState } from "recoil";
import {} from "recoil/selectors";
import styled from "styled-components";
import { PolicyNonPopState, clickedOut } from "recoil/atoms";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const StyledReactTooltip = styled.div`
  max-width: 250px;
  width:100%;
  z-index: 100;
  opacity: 0.99 !important;
  border: solid 1px #000000;
  box-shadow:0px 0px 25px #111;
  padding:4%;
  background-color:#FCFCFC;
  position: absolute;
  bottom:-30px;
  left:110%;
  margin-left:-11px;
`;

const PolicyDescriptionPopup = (data, id = "", ...rest) => {
  const [open, setOpen] = useRecoilState(PolicyNonPopState);
  const idName = data.name + 'Tip';
  const ref = useRef(null);
  const [clickOut, onClickOut ] = useRecoilState(clickedOut);

  const openTooltip = () => {
    setOpen(true);
  };

  const closeToolTip = () => {
    setOpen(false);
  };

  function AutoClickClose(event) {
    // Check to see if the click was inside an element of the tooltip
    if (ref.current && !ref.current.contains(event.target)) {
      closeToolTip();
    }
  }

  // Looks for a click outside of the box to close it
  useEffect(() => {
    // Setup the mouse event listener
    document.addEventListener("mousedown", AutoClickClose);
    return () => {
      // Remove the mouse event listener once finished
      document.removeEventListener("mousedown", AutoClickClose);
    };
  }, [clickOut]);
  
  return (
    <>
      {
        <>
        {rest.nowrap ? (
            <span>
              {data.children}
            </span>) : (
            <span onMouseOver={openTooltip}>
              {data.children}
              { open && <StyledReactTooltip
                className="socos-subplot-popup"
                position="top center"
                size="mini"
                id={idName}
                place={"right"}
                effect={"solid"}
                type={"light"}
                clickable={true}
                style={{ display: "flex" }}
                ref={ref}
              >
                <div  style={{ width: "100%" }}>
                  {" " + data.hoverData.description + " "}
                  <br /><br />
                  Additional Information about the policy can be found at: <br />
                  <b><a style={{color: "#155493", textDecoration: "underline"}} href={data.hoverData.source_url} target="_blank">{data.hoverData.source}</a></b>
                </div>
                <div style={{ textAlign: "right", width: "0%", cursor:"pointer", margin: "-6px 5px 0 0" }} onClick={closeToolTip}>
                  <FontAwesomeIcon icon={faXmark} style={{ fontSize:"1.2em", color: "#0000000" }} />
                </div>
              </StyledReactTooltip> }
            </span>
          )}
          </>
      }
    </>
  );
};

export default PolicyDescriptionPopup;
