import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { pristineState, plotXAxisState, visState, xLogScaleState } from "recoil/atoms";
import { plotFeaturesState, analyticsDataState } from "recoil/selectors";
import { Dropdown, Checkbox } from "semantic-ui-react";

const XAxisSelector = () => {
  const [feature, setFeature] = useRecoilState(plotXAxisState);
  const features = useRecoilValue(plotFeaturesState);
  const [pristine, setPristine] = useRecoilState(pristineState);
  const vis = useRecoilValue(visState);
  const analyticsData = useRecoilValue(analyticsDataState);
  const [logScale, setLogScale] = useRecoilState(xLogScaleState);

  const handleClick = (e, data) => {
    setFeature(data.value);
    // analytics.logEvent(`plot_x_change`, analyticsData);
    setPristine(false);
  };

  const handleToggle = (event, data) => {
    setLogScale(!logScale);
  };

  return (
    <>
      {vis === "plot" ?
        <>
          <Dropdown
            upward={true}
            id="x-axis-selector"
            className="mini"
            button
            options={features}
            defaultValue={feature}
            onChange={handleClick}
            style={{
              opacity: 1,
              boxShadow: "0px 0px 5px #a4a0a0",
              border: "solid 1px #CFCFCF"
            }}
          />
          <Checkbox
            toggle
            checked={logScale}
            onChange={handleToggle}
            label="log scale"
            style={{
              opacity: 1,
              display: "inline-block",
              marginLeft: "0.5vw",
              padding: "12px",
              boxShadow: "0px 0px 5px #a4a0a0",
              border: "solid 1px #CFCFCF",
              borderRadius: "8px",
              backgroundColor: "#e0e1e2",
              fontSize: "1rem"
            }}
          />
        </>
        : <div style={{minHeight:"3.75em"}}></div>
      }
    </>
  );
};

export default XAxisSelector;
