import React from "react";
import { Popup } from "semantic-ui-react";
import { useRecoilState } from "recoil";
import {} from "../../../recoil/selectors";
import { hoveredPolicyBarGraphDomState, hoveredPolicyBarDataState } from "../../../recoil/atoms";
import format from "../../../res/formats";

const BarGraphPopup = () => {
  const [hoverDom, setHoveredDom] = useRecoilState(hoveredPolicyBarGraphDomState);
  const [hoverData, setHoverData] = useRecoilState(hoveredPolicyBarDataState);

  return (
    <>
      {
        <Popup
          className="socos-subplot-popup"
          position="top center"
          context={hoverDom}
          open={hoverDom && hoverData}
          size="mini"
        >
          <Popup.Content
            className="socos-styles"
            style={{ textAlign: "center" }}
          >
            {hoverData && (
              <>
                <b>{hoverData.name}</b>
                <br />
                {format(hoverData.type, hoverData.y, true)}
              </>
            )}
          </Popup.Content>
        </Popup>
      }
    </>
  );
};

export default BarGraphPopup;
