import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import { useRecoilState } from "recoil";
import { hoveredSubPlotDomState, hoveredSubPlotDataState} from "recoil/atoms";
import format from "res/formats";
import styled from "styled-components";

const PlotContainer = styled.div`
  .plot-y-axis .tick line, 
  .plot-x-axis .tick line {
    stroke: #000000 !important;
  }
  
  .plot-y-axis .tick text, 
  .plot-x-axis .tick text {
    font-size: 0.5rem;
  }

  .axisLevel {
    font-weight: bold;
    font-size: 0.7rem;
  }
`;

const Label = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
  padding:2%;
`;

const width = 240;
const height = 150;
const margin = { left: 60, right: 10, top: 10, bottom: 10 };
const innerWidth = width - margin.left - margin.right;

// X axis scaling
export const setXScale = (h) => {
  return (
    d3.scaleLinear()
      .domain([0, (d3.max(h, (d) => { return d.x; }))*1.1])
      .nice()
      .range([0 + margin.left, width - margin.right - 25])
  );
};

// y-axis scaling
export const setYScale = (h) => {
  return (
    d3.scaleLinear()
      .domain([0, (d3.max(h, (d) => { return d.y;})*1.1)])
      .nice()
      .range([height - 0.4 * margin.bottom, margin.top])
  );
};


// Line graph object
const PolicyGraph = ({ dataType, actualVal, predictedVal, predictedHighVal, predictedLowVal }) => {
  const svgRef = useRef(null);
  var intervals = 10;

  // Add log values in between
  var data = AddLogValues(actualVal, predictedVal, "predicted", intervals);
  var highData = AddLogValues(actualVal, predictedHighVal, "high", intervals);
  var lowData = AddLogValues(actualVal, predictedLowVal, "low", intervals);
  var displayXtype = dataType.charAt(0).toUpperCase() + dataType.slice(1);

  const [hoverDom, setHoveredDom] = useRecoilState(hoveredSubPlotDomState);
  const [hoverData, setHoverData] = useRecoilState(hoveredSubPlotDataState);


  // initialize and draw axes
  useEffect(() => {
    const svg = d3.select(svgRef.current);

    // Add X axis
    var xScale = setXScale(highData);
    var xAxisGroup = svg.append("g")
        .attr("class", "x axis plot-axis plot-x-axis")
        .attr("transform", "translate(0," + (height - 0.4 * margin.bottom) + ")");

    var xAxis = d3.axisBottom()
        .scale(xScale)
        .ticks(5)
        .tickSize(-innerWidth+35)
        .tickSizeOuter(2)
        .tickFormat((n) => format("", n, false));

    xAxisGroup.call(xAxis);
    xAxisGroup.selectAll('.tick line').attr('opacity', 0.1);

    // Add Y axis
    var yScale = setYScale(highData);
    var yAxisGroup = svg.append("g")
        .attr("class", "y axis plot-axis plot-y-axis")
        .attr("transform", "translate(" + margin.left + ",0)");

    var yAxis = d3.axisLeft()
        .scale(yScale)
        .ticks(intervals/2)
        .tickSize(-innerWidth+25)
        .tickSizeOuter(3)
        .tickFormat((n) => format(dataType, n, false));

    yAxisGroup.call(yAxis);
    yAxisGroup.selectAll('.tick line').attr('opacity', 0.2);

    return () => {};
  }, []);

  // initialize and draw axes
  useEffect(() => {
    var svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Removes all children parts for redraw

    // Add X axis
    var xScale = setXScale(highData);
    var xAxisGroup = svg.append("g")
        .attr("class", "x axis plot-axis plot-x-axis")
        .attr("transform", "translate(0," + (height - 0.4 * margin.bottom) + ")");

    var xAxis = d3.axisBottom()
        .scale(xScale)
        .ticks(5)
        .tickSize(-innerWidth+35)
        .tickSizeOuter(2)
        .tickFormat((n) => format("", n, false));

    xAxisGroup.call(xAxis);
    xAxisGroup.selectAll('.tick line').attr('opacity', 0.1);

    // Add Y axis
    var yScale = setYScale(highData);
    var yAxisGroup = svg.append("g")
        .attr("class", "y axis plot-axis plot-y-axis")
        .attr("transform", "translate(" + margin.left + ",0)");

    var yAxis = d3.axisLeft()
        .scale(yScale)
        .ticks(intervals/2)
        .tickSize(-innerWidth+25)
        .tickSizeOuter(3)
        .tickFormat((n) => format(dataType, n, false));

    yAxisGroup.call(yAxis);
    yAxisGroup.selectAll('.tick line').attr('opacity', 0.2);

    // Lines
    var redLine = d3.line().x(function(d) { return xScale(d.x); }).y(function(d) { return yScale(d.y); });
    var greenLine = d3.line().x(function(d) { return xScale(d.x); }).y(function(d) { return yScale(d.y); });
    var blueLine = d3.line().x(function(d) { return xScale(d.x); }).y(function(d) { return yScale(d.y); });

    svg.append("path")
      .data([highData])
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 1.5)
      .attr("d", redLine);

    svg.append("path")
      .data([data])
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", "green")
      .attr("stroke-width", 1.5)
      .attr("d", greenLine);

    svg.append("path")
      .data([lowData])
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 1.5)
      .attr("d", blueLine);

    svg.append("text") // text label for the x axis
      .attr("x", 125)
      .attr("y", 170)
      .style("text-anchor", "middle")
      .attr("class", "axisLevel")
      .text("Years");

    svg.append("text") // text label for the y axis
      .attr("x", -75)
      .attr("y", 15)
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "middle")
      .attr("class", "axisLevel")
      .text(dataType === 'funding'? "Dollars" : displayXtype);
      
    return () => {};
  }, [data, highData, lowData]);

  return (
    <>
      <Label>{data.name}</Label>
      <PlotContainer className="plot-container">
        <svg
          viewBox={`0 0 ${width} ${height}`}
          preserveAspectRatio="xMinYMin meet"
          className="d3-component plot-content"
          ref={svgRef}
        />
      </PlotContainer>
    </>
  );
};

function AddLogValues(startVal, endVal, type, intervals) {
  var newData = [];
  var slope = (endVal-startVal)/intervals;
  var newVal = 0;

  newData[0] = {
    x: 0,
    y: startVal,
    name: "initial"
  };

  for(var i = 1; i < intervals; i++) {
    // Calculate log position
    newVal = slope * i + startVal;

    // Add a new point
    newData[i] = {
      x: i,
      y: newVal,
      name: type
    }
  }

  // Add last point
  newData[intervals] = {
    x: intervals,
    y: endVal,
    name: type
  };

  return newData;
}

export default PolicyGraph;
