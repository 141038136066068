import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { pristineState, chartFeatureState, visState, chartLogScaleState } from "recoil/atoms";
import { chartFeaturesState, analyticsDataState } from "recoil/selectors";
import { Dropdown, Checkbox } from "semantic-ui-react";

const FeatureSelector = () => {
  const [feature, setFeature] = useRecoilState(chartFeatureState);
  const features = useRecoilValue(chartFeaturesState);
  const [pristine, setPristine] = useRecoilState(pristineState);
  const vis = useRecoilValue(visState);
  const analyticsData = useRecoilValue(analyticsDataState);
  const [logScale, setLogScale] = useRecoilState(chartLogScaleState);

  const handleClick = (e, data) => {
    setFeature(data.value);
    // analytics.logEvent(`chart_feature_change`, analyticsData);
    setPristine(false);
  };

  const handleToggle = (event, data) => {
    setLogScale(!logScale);
  };

  return (
    <>
      <Dropdown
        id="feature-selector"
        className="mini"
        button
        options={features}
        defaultValue={feature}
        onChange={handleClick}
        style={{
          opacity: 1,
          display: "inline-block",
          marginLeft: ".5vw",
          marginTop: "2em",
          boxShadow: "0px 0px 5px #a4a0a0",
          border: "solid 1px #CFCFCF"
        }}
      />
      <Checkbox
        toggle
        checked={logScale}
        onChange={handleToggle}
        label="log scale"
        style={{
          opacity: 1,
          display: "inline-block",
          marginLeft: "0.5vw",
          padding: "12px",
          boxShadow: "0px 0px 5px #a4a0a0",
          border: "solid 1px #CFCFCF",
          borderRadius: "8px",
          backgroundColor: "#e0e1e2",
          fontSize: "1rem"
        }}
      />
    </>
  );
};

export default FeatureSelector;
