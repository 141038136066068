import React from "react";
import { Grid, } from "semantic-ui-react";
import { useRecoilValue } from "recoil";
import { selectedMetroState } from "recoil/selectors";
import styled from "styled-components";
import format from "res/formats";
import InfoTip from "components/InfoTip";

const StyledDiv = styled.div`
  transition: opacity 300ms linear;
  position: absolute;
  top: 0.5em;
  width: 100%;
  font-size:0.9vw;
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
  
`;

const Key = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-top: 0.5em;
  padding-bottom: 1em;
  font-weight:lighter;
`;

const StatLabel = styled.div`
  font-size: clamp(12px, 1vw, 15px);
  letter-spacing:0.3px;
  line-spacing:1.1;
  font-weight: bold;
`;

const StatValue = styled.div`
  font-size: clamp(14px,1.3vw,23px);
  margin-bottom:clamp(0px,6%,12px);
  margin-top:1%;
  letter-spacing:0.4px;
  font-weight: bold;
`;

const StatHeader = styled.div`
  font-size: clamp(12px,1.6vw,19px);
  letter-spacing:0.3px;
  line-spacing:1.1;
  font-weight: bold;
  padding:4% 3%;
`;

function InfoCard({ active }) {
  const metro = useRecoilValue(selectedMetroState);

  return (
    <>
      <StyledDiv active={active}>
        {metro.No_Statistical_Data === false ?
          <Grid className="socos-styles" celled style={{ margin: 0 }}>
            <Grid.Row style={{ borderBottom: "2px solid #757575" }}>
              <Grid.Column width={8} style={{ "text-align": "center" }} >
                {metro.population_text === "LGBTQ" ?
                  <InfoTip as="span" name="PanelEstimate">
                    <StatHeader style={{ "color": "rgb(13, 49, 107)" }}> Estimated </StatHeader>
                  </InfoTip>
                  :
                  <InfoTip as="span" name="PanelReported">
                    <StatHeader> Reported </StatHeader>
                  </InfoTip>
                }
              </Grid.Column>
              <Grid.Column width={8} style={{ "text-align": "center" }} >
                <InfoTip as="span" name="PanelAchievables">
                  <StatHeader style={{ "color": "rgb(91, 55, 102)" }}> Achievables</StatHeader>
                </InfoTip>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8} style={{ "text-align": "center" }}>
                {metro.population_text === "LGBTQ" ?
                  <>
                    <StatValue style={{ "color": "rgb(13, 49, 107)" }}>
                      {format("entrepreneurs", metro.entrepreneurs_estimate, false)}
                    </StatValue><StatLabel style={{ "color": "rgb(13, 49, 107)" }}>
                      {metro.population_text} Entrepreneurs
                    </StatLabel>
                  </>
                  :
                  <>
                    <StatValue>
                      {format("entrepreneurs", metro.entrepreneurs, false)}
                    </StatValue>
                    <StatLabel>
                      {metro.population_text} Entrepreneurs
                    </StatLabel>
                  </>
                }

              </Grid.Column>
              <Grid.Column width={8} style={{ "text-align": "center" }} >
              <InfoTip as="span" name="PanelAchievables">
                  <StatValue style={{ "color": "rgb(91, 55, 102)" }}>
                    {format("entrepreneurs", metro.entrepreneurs_achievable, false)}
                  </StatValue>
                  <StatLabel style={{ "color": "rgb(91, 55, 102)" }}> {metro.population_text} Entrepreneurs</StatLabel>
                  </InfoTip>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8} style={{ "text-align": "center" }} >
                {metro.population_text === "LGBTQ" ?
                  <>
                    <StatValue style={{ "color": "rgb(13, 49, 107)" }}>
                      {format("funding", metro.funding_estimate, false)}
                    </StatValue>
                    <StatLabel style={{ "color": "rgb(13, 49, 107)" }}> {metro.view_prefix} Funding </StatLabel>
                  </>
                  :
                  <>
                    <StatValue>
                      {format("funding", metro.funding, false)}
                    </StatValue>
                    <StatLabel> {metro.view_prefix} Funding </StatLabel>
                  </>
                }
              </Grid.Column>
              <Grid.Column width={8} style={{ "text-align": "center" }} >
              <InfoTip as="span" name="PanelAchievables">
                  <StatValue style={{ "color": "rgb(91, 55, 102)" }}>
                    {format("funding", metro.funding_achievable, false)}
                  </StatValue>
                  <StatLabel style={{ "color": "rgb(91, 55, 102)" }}> {metro.view_prefix} Funding </StatLabel>
                  </InfoTip>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8} style={{ "text-align": "center" }} >
                {metro.population_text === "LGBTQ" ?
                  <>
                    <StatValue style={{ "color": "rgb(13, 49, 107)" }}>
                      {metro.view_prefix === "Mean" ? format("mean", metro.jobs_estimate, false) : format("jobs", metro.jobs_estimate, false)}
                    </StatValue>
                    <StatLabel style={{ "color": "rgb(13, 49, 107)" }}>
                      {metro.view_prefix} Jobs
                    </StatLabel>
                  </>
                  :
                  <>
                    <StatValue>
                      {metro.view_prefix === "Mean" ? format("mean", metro.jobs, false) : format("jobs", metro.jobs, false)}
                    </StatValue>
                    <StatLabel>
                      {metro.view_prefix} Jobs
                    </StatLabel>
                  </>
                }
              </Grid.Column>
              <Grid.Column width={8} style={{ "text-align": "center" }} >
              <InfoTip as="span" name="PanelAchievables">
                  <StatValue style={{ "color": "rgb(91, 55, 102)" }}>
                    {metro.view_prefix === "Mean" ? format("mean", metro.jobs_achievable, false) : format("jobs", metro.jobs_achievable, false)}
                  </StatValue>
                  <StatLabel style={{ "color": "rgb(91, 55, 102)" }}> {metro.view_prefix} Jobs </StatLabel>
                  </InfoTip>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8} style={{ "text-align": "center" }} >
                {metro.population_text === "LGBTQ" ?
                  <>
                    <StatValue style={{ "color": "rgb(13, 49, 107)" }}>
                      {metro.view_prefix === "Mean" ? format("mean", metro.exits_count_estimate) : format("exits_count", metro.exits_count_estimate, false)}
                    </StatValue>
                    <StatLabel style={{ "color": "rgb(13, 49, 107)" }}> {metro.view_prefix} Exits </StatLabel>
                  </>
                  :
                  <>
                    <StatValue>
                      {metro.view_prefix === "Mean" ? format("mean", metro.exits_count) : format("exits_count", metro.exits_count, false)}
                    </StatValue>
                    <StatLabel> {metro.view_prefix} Exits </StatLabel>
                  </>
                }
              </Grid.Column>
              <Grid.Column width={8} style={{ "text-align": "center" }} >
              <InfoTip as="span" name="PanelAchievables">
                  <StatValue style={{ "color": "rgb(91, 55, 102)" }}>
                    {metro.view_prefix === "Mean" ? format("mean", metro.exits_count_achievable, false) : format("exits_count", metro.exits_count_achievable, false)}
                  </StatValue>
                  <StatLabel style={{ "color": "rgb(91, 55, 102)" }}>{metro.view_prefix} Exits</StatLabel>
                  </InfoTip>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8} style={{ "text-align": "center" }} >
                {metro.population_text === "LGBTQ" ?
                  <>
                    <StatValue style={{ "color": "rgb(13, 49, 107)" }}>
                      {metro.view_prefix === "Mean" ? format("mean", metro.patents_estimate) : format("patents", metro.patents_estimate, false)}
                    </StatValue>
                    <StatLabel style={{ "color": "rgb(13, 49, 107)" }}> {metro.view_prefix} Patents </StatLabel>
                  </>
                  :
                  <>
                    <StatValue>
                      {metro.view_prefix === "Mean" ? format("mean", metro.patents) : format("patents", metro.patents, false)}
                    </StatValue>
                    <StatLabel> {metro.view_prefix} Patents </StatLabel>
                  </>
                }
              </Grid.Column>
              <Grid.Column width={8} style={{ "text-align": "center" }} >
                {" "}
                <InfoTip as="span" name="PanelAchievables">
                  <StatValue style={{ "color": "rgb(91, 55, 102)" }}>
                    {metro.view_prefix === "Mean" ? format("mean", metro.patents_achievable) : format("patents", metro.patents_achievable, false)}
                  </StatValue>
                  <StatLabel style={{ "color": "rgb(91, 55, 102)" }}>{metro.view_prefix} Patents</StatLabel>
                  </InfoTip>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          : ""}

        <Key>
          <Grid className="socos-styles" celled style={{ margin: 0 }}>
          <Grid.Row>
              <Grid.Column width={16} style={{ "text-align": "center" }} >
                  <StatValue>Overview</StatValue>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8} style={{ "text-align": "center" }} >
                <InfoTip as="span" name="PanelGDP">
                  <StatValue>
                    {format("gdp", metro.gdp, false)}
                  </StatValue>
                  <StatLabel>GDP</StatLabel>
                </InfoTip>
              </Grid.Column>
              <Grid.Column width={8} style={{ "text-align": "center" }} >
                <InfoTip as="span" name="PanelAllEntrepreneurs">
                  <StatValue>
                    {format("all_entrepreneurs", metro.all_entrepreneurs, false)}
                  </StatValue>
                  <StatLabel>All Entrepreneurs</StatLabel>
                </InfoTip>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8} style={{ "text-align": "center" }} >
                <InfoTip as="span" name="PanelGDPperCap">
                  <StatValue>
                    {format("gdp", metro.gdp_per_capita, false)}
                  </StatValue>
                  <StatLabel>GDP per Capita</StatLabel>
                </InfoTip>
              </Grid.Column>
              <Grid.Column width={8} style={{ "text-align": "center" }} >
                <InfoTip as="span" name="PanelPopulation">
                  <StatValue>
                    {format("population", metro.population, false)}
                  </StatValue>
                  <StatLabel>Population</StatLabel>
                </InfoTip>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Key>
      </StyledDiv>
    </>
  );
}

export default InfoCard;
