import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import { useRecoilState } from "recoil";
import { hoveredPolicyBarGraphDomState, hoveredPolicyBarDataState } from "../../../recoil/atoms";
import format from "../../../res/formats";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare as fasFaSquare } from "@fortawesome/free-solid-svg-icons";


const PlotContainer = styled.div`
  .plot-y-axis .tick line, 
  .plot-x-axis .tick line {
    stroke: #000000 !important;
    padding-left:40px;
  }

  .plot-y-axis .tick text, 
  .plot-x-axis .tick text {
    font-size: 0.5rem;
  }

  .axisLevel {
    font-weight: bold;
    font-size: 0.75rem;
    padding-left:40px;
  }
`;

const Label = styled.div`
  text-align: center; 
  font-weight: bold;
  font-size: clamp(11px, 0.88vw, 14px);
  padding:0 2% 1% 2%;
  margin:0;
  line-height:clamp(11px, 1.3vw, 24px);
`;

const LabelBox = styled.div`
  text-align: center; 
  font-weight: bold;
  font-size: clamp(11px, 0.88vw, 16px);
  width:48%;
`;


const width = 200;
const height = 120;
const margin = { left: 80, right: 10, top: 10, bottom: 10 };
const innerWidth = width - margin.left - margin.right;

// X axis scaling
export const setXScale = (h) => {
  return (
    d3.scaleLinear()
      .domain([0, (d3.max(h, (d) => { return d.x; }))*1.1])
      .nice()
      .range([0 + margin.left, width - margin.right])
  );
};

// y-axis scaling
const yScale = (data) => {
  return d3.scaleLinear()
    .domain([0, d3.max(data, (d) => { return d.y; })*1.1])
    .nice()
    .range([height - margin.bottom, margin.top]);
};

const y = (data, n) => yScale(data)(n);

const BarGraph = ({ dataType, feature, title }) => {
  const svgRef = useRef(null);
  //Format the data for the graph display
  const data = feature;
 
  const [hoverDom, setHoveredDom] = useRecoilState(hoveredPolicyBarGraphDomState);
  const [hoverData, setHoverData] = useRecoilState(hoveredPolicyBarDataState);

  const handleOver = (d, elem) => {
    setHoverData(d);
    setHoveredDom(elem);
  };

  const handleOut = (d, elem) => {
    setHoverData(null);
    setHoveredDom(null);
  };
  
  // initialize and draw axes
  useEffect(() => {
    const svg = d3.select(svgRef.current);

    // Add X axis
    var xScale = setXScale(data);
    var xAxisGroup = svg.append("g")
        .attr("class", "x axis plot-axis plot-x-axis")
        .attr("transform", "translate(-30, 110)");

    var xAxis = d3.axisBottom()
        .scale(xScale)
        .ticks(0)
        .tickSize(-innerWidth)
        .tickSizeOuter(1)
        .tickFormat((n) => format("", n, false));

    xAxisGroup.call(xAxis);
    xAxisGroup.selectAll('.tick line').attr('opacity', 0.1);

    var scale = yScale(data);
    // Add Y axis
    var yAxisGroup = svg.append("g")
      .attr("class", "y axis plot-axis plot-y-axis")
      .attr("transform", "translate(" + margin.left + ",0)");

    var yAxis = d3
      .axisLeft()
      .scale(scale)
      .ticks(5)
      .tickSize(-innerWidth-30)
      .tickSizeOuter(3)
      .tickFormat((n) => format(dataType, n, false));

    yAxisGroup.call(yAxis);
    yAxisGroup.selectAll('.tick line').attr('opacity', 0.2);

    return () => {};
  }, []);

  // update scales and axese on options changes
  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Removes all children parts to redraw

    const plotWidth = innerWidth;
    const numBars = 2;
    const padding = 5;
    const barWidth = (plotWidth - (numBars + 1) * padding - 10) / numBars;

    // Add X axis
    var xScale = setXScale(data);
    var xAxisGroup = svg.append("g")
        .attr("class", "x axis plot-axis plot-x-axis")
        .attr("transform", "translate(-20,110)");

    var xAxis = d3.axisBottom()
        .scale(xScale)
        .ticks(0)
        .tickSize(-innerWidth)
        .tickSizeOuter(1);

    xAxisGroup.call(xAxis);
    xAxisGroup.selectAll('.tick line').attr('opacity', 0.1);

    var scale = yScale(data);

    var yAxisGroup = svg.append("g")
      .attr("class", "y axis plot-axis plot-y-axis")
      .attr("transform", "translate(60,0)");

    var yAxis = d3.axisLeft()
      .scale(scale)
      .ticks(5)
      .tickSize(-innerWidth)
      .tickSizeOuter(3)
      .tickFormat((n) => format(dataType, n, false));

    yAxisGroup.call(yAxis);
    yAxisGroup.selectAll('.tick line').attr('opacity', 0.2);

    // Update process
    const update = svg.selectAll("rect.plot-bars").data(data);
    update
      .join("rect")
      .attr("class", "plot-bars")
      .on("mouseover", function (d) {
        handleOver(d, this);
      })
      .on("mouseout", function (d) {
        handleOut(d, this);
      })
      .attr("x", (d) => (margin.left + d.x * padding + (d.x - 1) * barWidth + 5)+30)
      .attr("y", (d) => y(data, d.y) || 0)
      .attr("width", barWidth)
      .attr("height", (d) => y(data, 0) - y(data, d.y) || 0)
      .attr("fill", (d) => d.color);

    svg.append("text") // text label for the y axis
      .attr("x", -75)
      .attr("y", 20)
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "middle")
      .attr("class", "axisLevel")
      .text(dataType.substring(0,1).toUpperCase() + dataType.substring(1));

    return () => {};
  }, [data]);

  return (
    <>
      <Label>{title}</Label>
      <PlotContainer className="plot-container">
        <svg
          viewBox={`0 0 ${width} ${height}`}
          preserveAspectRatio="xMinYMin meet"
          className="d3-component plot-content"
          ref={svgRef}
        />
        <div style={{display: "flex"}}>
          <LabelBox>
          <FontAwesomeIcon
                        icon={fasFaSquare}
                        style={{ color: "rgb(13, 49, 107)" }} />
                      &nbsp;&nbsp;&nbsp;
                      <span className="has-text-weight-bold info-actual-estimate">
                        Actual
                      </span>
          </LabelBox>
          <LabelBox>
          <FontAwesomeIcon
                        icon={fasFaSquare}
                        style={{ color: "#ad8961" }} />
                      &nbsp;&nbsp;&nbsp;
                      <span className="has-text-weight-bold info-actual-estimate">
                        Predicted
                      </span>
          </LabelBox>
        </div>
      </PlotContainer>
    </>
  );
};

export default BarGraph;
