import React from "react";
import { Button } from "semantic-ui-react";
import { useRecoilState } from "recoil";
import { visState, pristineState } from "recoil/atoms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import {
  faGlobeAmericas,
  faChartLine,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";

const StyledButton = styled.div`
  .button {
    width:content-fit;
    font-size:clamp(12px,1.1vw,16px);
    border solid 1px #CFCFCF;
  }

  .ui.button, .ui.active.button {
    width:content-fit;
    font-size:clamp(12px,1.1vw,16px);
    border solid 1px #CFCFCF;
    box-shadow:0px 0px 5px #a4a0a0;
  }

`;

const VisModeSelector = () => {
  const [vis, setVis] = useRecoilState(visState);
  const [pristine, setPristine] = useRecoilState(pristineState);

  const handleClick = (mode) => {
    setVis(mode);
    setPristine(false);
  };

  return (
    <StyledButton>
      <Button
        icon
        color={vis === "map" ? "teal" : ""}
        active={vis === "map"}
        onClick={() => handleClick("map")}
      >
        <FontAwesomeIcon icon={faGlobeAmericas} />
        &nbsp;{"  Map"}
      </Button>

      <Button
        icon
        color={vis === "plot" ? "teal" : ""}
        active={vis === "plot"}
        onClick={() => handleClick("plot")}
      >
        <FontAwesomeIcon icon={faChartLine} />
        &nbsp;{"  Plot"}
      </Button>

      <Button
        icon
        color={vis === "chart" ? "teal" : ""}
        active={vis === "chart"}
        onClick={() => handleClick("chart")}
      >
        <FontAwesomeIcon icon={faChartBar} />
        &nbsp;{"  Chart"}
      </Button>
    </StyledButton>
  );
};

export default VisModeSelector;
