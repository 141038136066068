import React from "react";
import ReactTooltip from "react-tooltip";
import {} from "../../../recoil/selectors";
import format from "../../../res/formats";
import styled from "styled-components";

const StyledReactTooltip = styled(ReactTooltip)`
  max-width: 15%;
  z-index: 999999;
  &.show {
    opacity: 0.99 !important;
  }
  border: solid 1px #000000;
  box-shadow:0px 0px 25px #111;
  line-height:clamp(16px,1.3vw,23px);
  font-size:clamp(11px, 0.88vw, 15px);
`;

const popupText = styled.div`
  font-size:clamp(18px, 0.9vw, 28px);
  line-height:clamp(22px, 0.9vw, 32px);
  font-weight:bold;
`;

const ConfidencePopup = (data, id = "", ...rest) => {
  return (
    <>
      {
        <>
          {rest.nowrap ? (
            <span>
              {data.children}
            </span>) : (
            <span id={id} data-tip data-for={`${data.name}Tip`}>
              {data.children}
            </span>
          )}
          <StyledReactTooltip
            className="socos-subplot-popup"
            position="top center"
            size="mini"
            id={`${data.name}Tip`}
            place={"top"}
            effect={"solid"}
            type={"light"}
          ><popupText>
            This value was calculated using the difference-in-differences statistical technique by comparing regions that did and didn't pass this policy.
            <br /><br />
            A confidence of <b>{" " + data.hoverData.confidence_level + " "}</b> 
            is derived from our confidence level of {" " + data.hoverData.confidence_percent + "%"}.
            </popupText>
          </StyledReactTooltip></>
      }
    </>
  );
};

export default ConfidencePopup;
