const settings = {
  vis: {
    svg: {
      dims: {
        width: 1000,
        height: 600,
        margin: { left: 50, top: 20, right: 40, bottom: 100 },
      },
    },
  },
};

export default settings;
