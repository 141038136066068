import React from "react";
import { Button } from "semantic-ui-react";
import { useRecoilState } from "recoil";
import { regionLevelState } from "recoil/atoms";
import styled from "styled-components";

const StyledButton = styled.div`
  margin-left:1%;

  .button {
    width:content-fit;
    font-size:clamp(12px,1.1vw,16px);
    border solid 1px #CFCFCF;
  }

  .ui.button, .ui.active.button {
    width:content-fit;
    font-size:clamp(12px,1.1vw,16px);
    border solid 1px #CFCFCF;
    box-shadow:0px 0px 5px #a4a0a0;
  }

`;

/*
 * This is the geographic layer selector section
 */

const RegionLevelSelector = () => {
  const [regionLevel, setRegionLevel] = useRecoilState(regionLevelState);

  const handleClick = (level) => {
    setRegionLevel(level);
  };

  return (
    <StyledButton>
      <Button
        color={regionLevel === "metro" ? "teal" : ""}
        active={regionLevel === "metro"}
        onClick={() => handleClick("metro")}
      >
        Metros
      </Button>
      <Button
        color={regionLevel === "state" ? "teal" : ""}
        active={regionLevel === "state"}
        onClick={() => handleClick("state")}
      >
        States
      </Button>
      {/* <Button
        color={regionLevel === "country" ? "teal" : null}
        active={regionLevel === "country"}
        onClick={() => handleClick("country")}
      >
        Countries
      </Button> */}
    </StyledButton>
  );
};

export default RegionLevelSelector;
