import { atom } from "recoil";

/*
 * This file sets the defaults when it is first loaded
 */

// Atoms
export const pristineState = atom({
  key: "pristineState",
  default: true,
});
export const viewState = atom({
  key: "viewState",
  default: "Total",
});
export const populationState = atom({
  key: "populationState",
  default: "LGBTQ",
});
export const regionLevelState = atom({
  key: "regionLevelState",
  default: "metro",
});
export const visState = atom({
  key: "visState",
  default: "map",
});
export const chartFeatureState = atom({
  key: "chartFeatureState",
  default: "funding",
});
export const deploymentContextState = atom({
  key: "deploymentContextState",
  default: "startout-v2",
});

export const selectedMetroIDState = atom({
  key: "selectedMetroIDState",
  default: 0,
});
export const hoveredMetroIDState = atom({
  key: "hoveredMetroIDState",
  default: null,
});
export const mockContextState = atom({
  key: "mockContextState",
  default: false,
});
export const visWidthState = atom({
  key: "visWidthState",
  default: 500,
});
export const visHeightState = atom({
  key: "visHeightState",
  default: 400,
});
export const plotYAxisState = atom({
  key: "plotYAxisState",
  default: "gdp_per_capita",
});
export const plotXAxisState = atom({
  key: "plotXAxisState",
  default: "population",
});
export const industryPolygonsState = atom({
  key: "industryPolygonsState",
  default: [],
});
export const selectedIndustryIDState = atom({
  key: "selectedIndustryIDState",
  default: null,
});
export const selectedIndustryDomState = atom({
  key: "selectedIndustryDomState",
  default: null,
});
export const hoveredMetroDomState = atom({
  key: "hoveredMetroDomState",
  default: null,
});
export const voronoiDiagramState = atom({
  key: "voronoiDiagramState",
  default: null,
});
export const subPlotNonPopState = atom({
  key: "subPlotNonPopState",
  default: false,
});
export const PolicyNonPopState = atom({
  key: "PolicyNonPopState",
  default: false,
});
export const PolicyNumSelectedNonPopState = atom({
  key: "PolicyNumSelectedNonPopState",
  default: 0,
});
export const hoveredSubPlotDomState = atom({
  key: "hoveredSubPlotDomState",
  default: null,
});

export const hoveredSubPlotDataState = atom({
  key: "hoveredSubPlotDataState",
  default: null,
});

export const hoveredPolicyBarGraphDomState = atom({
  key: "hoveredPolicyBarGraphDomState",
  default: null,
});

export const hoveredPolicyBarDataState = atom({
  key: "hoveredPolicyBarDataState",
  default: null,
});

export const metroBreakoutState = atom({
  key: "metroBreakoutState",
  default: "subplots",
});

export const yLogScaleState = atom({
  key: "yLogScaleState",
  default: false,
});

export const xLogScaleState = atom({
  key: "xLogScaleState",
  default: false,
});

export const chartLogScaleState = atom({
  key: "chartLogScaleState",
  default: false,
});

export const activeCountryState = atom({
  key: "activeCountryState",
  default: "USA",
});
export const clickedOut = atom({
  key: "clickedOut",
  default: false,
});