import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import { useRecoilState, useRecoilValue } from "recoil";
import { subPlotNonPopState, hoveredSubPlotDomState, hoveredSubPlotDataState } from "recoil/atoms";
import { selectedMetroState, subPlotData, showSubPlotNonPopState,} from "recoil/selectors";
import format from "res/formats";
import styled from "styled-components";

const PlotContainer = styled.div`
  .subplot-y-axis .tick line {
    stroke: grey !important;
  }
  .subplot-y-axis .tick text {
    font-size: 18px;
  }

  .subplot-y-axis path.domain {
    stroke: none;
  }
`;

const Label = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 1.3em;
  padding-top:0.5em;
  padding-bottom: 0.8em;
`;

const width = 200;
const height = 150;
const margin = { left: 60, right: 10, top: 10, bottom: 10 };
const innerWidth = width - margin.left - margin.right;

const yScale = (data) => {
  return d3.scaleLinear()
    .domain([0, d3.max(data, (d) => {  return d.y; })])
    .nice()
    .range([height - margin.bottom, margin.top]);
};

const y = (data, n) => yScale(data)(n);

const SubPlot = ({ label, feature }) => {
  const metro = useRecoilValue(selectedMetroState);
  const svgRef = useRef(null);
  const data = useRecoilValue(subPlotData(feature));
  const [nonPop, setNonPop] = useRecoilState(subPlotNonPopState);
  const showNonPop = useRecoilValue(showSubPlotNonPopState);
  const [hoverDom, setHoveredDom] = useRecoilState(hoveredSubPlotDomState);
  const [hoverData, setHoverData] = useRecoilState(hoveredSubPlotDataState);

  const handleOver = (d, elem) => {
    setHoverData(d);
    setHoveredDom(elem);
  };

  const handleOut = (d, elem) => {
    setHoverData(null);
    setHoveredDom(null);
  };
  // initialize and draw axes
  useEffect(() => {
    const svg = d3.select(svgRef.current);

    var scale = yScale(data);
    // Add Y axis
    var yAxisGroup = svg
      .append("g")
      .attr("class", "y axis subplot-axis subplot-y-axis")
      .attr("transform", "translate(" + margin.left + ",0)");

    var yAxis = d3
      .axisLeft()
      .scale(scale)
      .ticks(3)
      .tickSize(-innerWidth)
      .tickSizeOuter(0)
      .tickFormat((n) => format(feature, n, false));

    yAxisGroup.call(yAxis);

    return () => {};
  }, []);

  // update scales and axese on options changes
  useEffect(() => {
    const svg = d3.select(svgRef.current);

    const plotWidth = innerWidth;
    const numBars = showNonPop ? 3 : 2;
    const padding = 15;
    const barWidth = (plotWidth - (numBars + 1) * padding - 10) / numBars;
    var ticks = 0;
    var isDecimal = false;
    var reportedVal = 0;
    var achieveableVal = 0;
    var repAchDiff = 0;

    // If there are small values, remove additional added gridlines to prevent repeats of same values
    if(data !== undefined && data.length > 0) {
      for(var n = 0; n < data.length; n++) {
        if(data[n].name === "Reported") {
          reportedVal = data[n].y;
        }
        else if(data[n].name === "Achievable") {
          achieveableVal = data[n].y;
        }
      }

      // Determine the difference if Achievables has a value, reported is above 5 and both don't equal each other
      if(achieveableVal !== 0 && achieveableVal !== reportedVal
        && (reportedVal > 5 || (achieveableVal - reportedVal) > 0)) {
        repAchDiff = achieveableVal - reportedVal;
      }
      else {
        repAchDiff = reportedVal;
      }
      

      // Determine the best to use
      if(repAchDiff > 5) { // Default gridlines added
        ticks = 3;
      }
      else if (repAchDiff === 0) { // They are the same values, no gridlines added
        ticks = 1;
      }
      else if(repAchDiff > 1) { // Less than 5 values difference, add gridlines depending on amount
        ticks = repAchDiff-1;
      }
      else {
        isDecimal = true; // Using this flag to change the axis value formatting to decimals
        ticks = 2;
      }
    }

    var yAxis = d3
      .axisLeft()
      .scale(yScale(data))
      .ticks(ticks)
      .tickSize(-innerWidth)
      .tickSizeOuter(0)
      .tickFormat((n) => 
        isDecimal ? format("mean", n, false) :format(feature, n, false)
      );

    svg.selectAll(".subplot-y-axis").transition().duration(1500).call(yAxis);
    const update = svg.selectAll("rect.plot-bars").data(data);

    update
      .join("rect")
      .attr("class", "plot-bars")
      .on("mouseover", function (d) {
        handleOver(d, this);
      })
      .on("mouseout", function (d) {
        handleOut(d, this);
      })
      .transition()
      .duration(1500)
      .attr("x", (d) => margin.left + d.x * padding + (d.x - 1) * barWidth + 5)
      .attr("y", (d) => y(data, d.y) || 0)
      .attr("width", barWidth)
      .attr("height", (d) => y(data, 0) - y(data, d.y) || 0)
      .attr("fill", (d) => d.color);

    return () => {};
  }, [metro, nonPop]);

  return (
    <>
      <Label>{label}</Label>
      <PlotContainer className="subplot-container">
        <svg
          viewBox={`0 0 ${width} ${height}`}
          preserveAspectRatio="xMinYMin meet"
          className="d3-component subplot-content"
          ref={svgRef}
        />
      </PlotContainer>
    </>
  );
};

export default SubPlot;
