import React from "react";
import { Button } from "semantic-ui-react";
import { useRecoilState, useRecoilValue } from "recoil";
import { populationState } from "recoil/atoms";
import { analyticsDataState } from "recoil/selectors";
import useWindowSize from "../../../hooks/useWindowSize";
import styled from "styled-components";

const StyledButton = styled.div`
  .button {
    width:content-fit;
    font-size:clamp(12px,1.1vw,16px);
    border solid 1px #CFCFCF;
  }

  .ui.button, .ui.active.button {
    width:content-fit;
    font-size:clamp(12px,1.1vw,16px);
    border solid 1px #CFCFCF;
    box-shadow:0px 0px 5px #a4a0a0;
  }

`;

const VisModeSelector = () => {
  const [population, setPopulation] = useRecoilState(populationState);
  const analyticsData = useRecoilValue(analyticsDataState);
  const windowSize = useWindowSize();

  const handleClick = (pop) => {
    setPopulation(pop);
    // analytics.logEvent(`population_click_${pop}`, analyticsData);
  };
  return (
    <StyledButton>
      <Button
        color={population === "LGBTQ" ? "teal" : ""}
        active={population === "LGBTQ"}
        onClick={() => handleClick("LGBTQ")}
      >
        LGBTQ
      </Button>
      <Button
        color={population === "Women" ? "teal" : ""}
        active={population === "Women"}
        onClick={() => handleClick("Women")}
      >
        Women
      </Button>
      <Button
        color={population === "All" ? "teal" : ""}
        active={population === "All"}
        onClick={() => handleClick("All")}
      >
        All
      </Button>
    </StyledButton>
  );
};

export default VisModeSelector;
