import React from "react";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 2;
`;

const Loading = () => {
  return (
    <div className="sweet-loading">
      <GridLoader css={override} size={15} color={"#009c95"} loading={true} />
    </div>
  );
};

export default Loading;
