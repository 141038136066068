import React, { useEffect } from "react";
import { Popup, Grid } from "semantic-ui-react";
import { useRecoilValue } from "recoil";
import { hoveredMetroState } from "recoil/selectors";
import { hoveredMetroDomState, visState, plotXAxisState, plotYAxisState, chartFeatureState, populationState, viewState } from "recoil/atoms";
import format from "../../res/formats";
import styled from "styled-components";

const GeoLabel = styled.div`
  font-size:clamp(18px, 0.9vw, 28px);
  line-height:clamp(22px, 0.9vw, 32px);
  font-weight:bold;
  white-space: nowrap;
  padding:0 2px;
`;
const DataAmt = styled.div`
  font-size:clamp(16px, 0.9vw, 22px);
  line-height:clamp(20px, 0.9vw, 26px);
  padding-top:5px;
`;

const TextDisplay = styled.div`
  width:content-fit;
  padding:1%;
  text-align:center;
`;

const AxisLabel = styled.div`
  width:content-fit;
  font-size:clamp(12px, 0.8vw, 22px);
  line-height:clamp(16px, 0.8vw, 26px);
  text-align:center;
  white-space: nowrap;
  padding:0 5px;
`;

const VisPopup = () => {
  const metro = useRecoilValue(hoveredMetroState);
  const metroDom = useRecoilValue(hoveredMetroDomState);
  const vis = useRecoilValue(visState);
  const feature = useRecoilValue(chartFeatureState);
  const population = useRecoilValue(populationState);
  const view = useRecoilValue(viewState);


  const plotX = useRecoilValue(plotXAxisState);
  const plotY = useRecoilValue(plotYAxisState);

  // update based on ui changes
  useEffect(() => {
    return () => { };
  }, [metro]);

  return (
    <>
      {metro && (
        <Popup
          className="socos-popup socos-styles"
          size="mini"
          position="top center"
          basic
          context={metroDom}
          open={true}
        >
          <Popup.Content style={{ borderRadius: "4px", overflow: "hidden" }}>
            <Grid celled style={{ margin: 0 }}>
              <Grid.Row>
                <TextDisplay>
                  <GeoLabel>
                    {metro.city}
                  </GeoLabel>
                  <DataAmt>
                    {vis === "chart" ?
                      // Break down each option for the chart
                      <>
                        <AxisLabel>
                        {
                          feature === "funding" ? format("funding", metro[`${population}_Funding_${view}_Actual`], true) + " in funding"
                            : feature === "jobs" ? format("jobs", metro[`${population}_Jobs_${view}_Actual`], false) + " Jobs"
                              : feature === "patents" ? format("patents", metro[`${population}_Patents_${view}_Actual`], false) + " Patents"
                                : feature === "exits_count" ? format("mean", metro[`${population}_ExitCounts_${view}_Actual`], false) + " Exits"
                                  : feature === "index_number" ? format("count", metro[`${population}_Index_Score_${view}_Actual`], false) + " Index Score"
                                    : feature === "ent_proportion" && population === "All" ? format("decimal", metro[`${population}_Ent_Proportion_Actual`], false) + " Entrepreneurs per 100,000 people"
                                      : feature === "ent_proportion" && view !== "All" ? format("mean", metro[`${population}_Ent_Proportion_Actual`], false) + " Entrepreneurs per 100,000 people"
                                        : ""
                        }
                        </AxisLabel>
                      </>
                      : vis === "plot" ?
                        <div>
                          <AxisLabel>
                            { /* y axis formatting */}
                            {
                              plotY === "gdp_per_capita" ? format("money", metro[`GDP_Per_Capita`], true) + " GDP per capita "
                                : plotY === "population" ? format("popExpanded", metro[`Population`], true) + "  population "
                                  : plotY === "index_number" ? format("count", metro[`${population}_Index_Score_${view}_Actual`], false) + " index score "
                                    : plotY === "jobs" ? format("count", metro[`${population}_Jobs_${view}_Actual`], true) + " jobs "
                                      : plotY === "funding" ? format("money", metro[`${population}_Funding_${view}_Actual`], true) + " in funding "
                                        : plotY === "exits_count" ? format("mean", metro[`${population}_ExitCounts_${view}_Actual`], false) + " exits "
                                          : plotY === "patents" ? format("count", metro[`${population}_Patents_${view}_Actual`], false) + " patents "
                                            : ""
                            }
                          </AxisLabel>
                          <AxisLabel>
                            { /* x axis formatting */}
                            {
                              plotX === "gdp_per_capita" ? " " + format("money", metro[`GDP_Per_Capita`], true) + " GDP per capita"
                                : plotX === "population" ? " " + format("popExpanded", metro[`Population`], true) + " population"
                                  : plotX === "index_number" ? " " + format("count", metro[`${population}_Index_Score_${view}_Actual`], false) + " index score"
                                    : plotX === "jobs" ? " " + format("count", metro[`${population}_Jobs_${view}_Actual`], true) + " jobs"
                                      : plotX === "funding" ? " " + format("money", metro[`${population}_Funding_${view}_Actual`], true) + " in funding"
                                        : plotX === "exits_count" ? " " + format("mean", metro[`${population}_ExitCounts_${view}_Actual`], false) + " exits"
                                          : plotX === "patents" ? " " + format("count", metro[`${population}_Patents_${view}_Actual`], false) + " patents"
                                            : ""
                            }
                          </AxisLabel>
                        </div>
                        : "" // This is if it isn't a chart.
                    }
                  </DataAmt>
                </TextDisplay>
              </Grid.Row>
            </Grid>
          </Popup.Content>
        </Popup>
      )}
    </>
  );
};

export default VisPopup;
