import React from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { selectedMetroState, metroData, stateData, countryData } from "recoil/selectors";
import { Grid } from "semantic-ui-react";
import styled from "styled-components";
import format from "res/formats";
import LineGraph from "./LineGraph";
import BarGraph from "./BarGraph";
import BarGraphPopup from "./BarGraphPopup";
import PolicyDescriptionPopup from "./PolicyDescriptionPopup";
import ConfidencePopup from "./ConfidencePopup";
import { populationState, PolicyNumSelectedNonPopState, viewState, regionLevelState, selectedMetroIDState, PolicyNonPopState } from "recoil/atoms";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "semantic-ui-react";

const StyledDiv = styled.div`
  transition: opacity 300ms linear;
  position: absolute;
  top: 0.5em;
  height:127%;
  width: 100%;
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
  background-color: rgb(255, 255, 255);
`;

const Visual = styled.div`
  transition: opacity 300ms linear;
  position: absolute;
  width:72%;
  height:48%;
  padding: 0;
  margin:0 11%;
`;

const Prev = styled.div`
  transition: opacity 300ms linear;
  position: absolute;
  width: 8%;
  height: 12%;
  padding: 1%;
  text-align:center;
  margin:32% 0 0 0;
  cursor:pointer;
  font-size: 1.7em; 
  color: #0000000;
`;

const DisPrev = styled.div`
  transition: opacity 300ms linear;
  position: absolute;
  width: 8%;
  height: 12%;
  padding: 1%;
  text-align:center;
  margin:32% 0 0 0;
  font-size: 1.7em; 
  color: #CECECE;
`;

const Next = styled.div`
  transition: opacity 300ms linear;
  position: absolute;
  width: 8%;
  height: 12%;
  padding: 1%;
  text-align:center;
  margin:32% 0 0 86%;
  cursor:pointer;
  font-size: 1.7em; 
  color: #0000000;
`;

const DisNext = styled.div`
  transition: opacity 300ms linear;
  position: absolute;
  width: 8%;
  height: 12%;
  padding: 1%;
  text-align:center;
  margin:32% 0 0 86%;
  font-size: 1.7em; 
  color: #CECECE;
`;

const PageCount = styled.div`
  margin:112% 5% 0px 86%;
  border-radius:5px;
  background-color:#f7eb716b;
  padding:clamp(5px, 0.5vw, 11px);
  font-size:clamp(11px, 0.88vw, 12px);
  color:#2D2D2D;
  width:fit-content;
`;

const Descrip = styled.div`
  transition: opacity 300ms linear;
  position: absolute;
  line-height:clamp(16px,1.3vw,23px);
  top: 0.5em;
  margin:68% 4% 0px 1%;
  font-size:clamp(11px, 0.88vw, 15px);
  word-spacing:1px;
  padding:0 4% 0 5%;
`;

const Title = styled.div`
  text-align: center;
  margin: auto;
  font-size:clamp(12px,1.1vw,18px);
`;

const PolicyVisual = styled.div`
  height:100%;
  width:100%;
  font-size:0.92vw;
`;

const StyledButton = styled.div`
  .button {
    width:content-fit;
    border solid 1px #BBBBBB;
    color: rgb(77, 72, 72);
  }

  .ui.button, .ui.active.button {
    width:content-fit;
    font-size:clamp(12px,1.1vw,13px);
    border solid 1px #BBBBBB;
    box-shadow:0px 0px 5px #a4a0a0;
    padding: 8px 12px;
    color: rgb(77, 72, 72);
  }

  .ui.button:hover, .ui.active.button:hover, button:hover {
    background-color:#c2d7ec;
    border solid 1px #AFAFAF;
  }
`;

function GetPolicies(sGeo) {
  var pol = [];

  for (var i = 0; i < sGeo.Policies.length; i++) {
    pol.push(sGeo.Policies[i])
  }
  
  return pol;
}

function Policies({ active }) {
  // Used for redirection
  const [population, setPop] = useRecoilState(populationState);
  const [vw, setView] = useRecoilState(viewState);
  const [reg, setReg] = useRecoilState(regionLevelState);
  const [selectedMetroID, setLoc] = useRecoilState(selectedMetroIDState);

  const selGeo = useRecoilValue(selectedMetroState);
 // const population = (useRecoilValue(populationState)).toUpperCase();
  const view = useRecoilValue(viewState);
  const policies = GetPolicies(selGeo);
  const sugPol = selGeo.policySuggested;
  const [selected, setNumSelected] = useRecoilState(PolicyNumSelectedNonPopState);

  const theCountries = useRecoilState(countryData);
  const theMetros = useRecoilState(metroData);
  const theStates = useRecoilState(stateData);

  const [open, setOpen] = useRecoilState(PolicyNonPopState);

  const nextPolicy = () => {
    closeToolTip(); // Make sure the policy tooltip is closed before moving to another one to prevent click-off from not working.
    
    // Get the first policy if reached the end of the list
    if (selected >= policies.length - 1) {
      setNumSelected(0);
    }
    else { // Next policy
      var cnt = selected + 1;
      setNumSelected(cnt);
    }
  };

  const prevPolicy = () => {
    closeToolTip(); // Make sure the policy tooltip is closed before moving to another one to prevent click-off from not working.

    // Get the first policy if reached the end of the list
    if (selected <= 0) {
      setNumSelected(policies.length - 1);
    }
    else { // Next policy
      var cnt = selected - 1;
      setNumSelected(cnt);
    }
  };

  const closeToolTip = () => {
    setOpen(false);
  };

  // This function changes the geography layers that are selected to navigate to a 
// particular policy
const GeographyRedirect = (selectedPolicy) => {
  // Set values
  if(selectedPolicy) {
    setReg(selectedPolicy[0].geolevel);

    if(selectedPolicy[0].geolevel === "metro") {
        for(var x = 0; x < theMetros[0].length; x++) {
          if(theMetros[0][x].DisplayName === selectedPolicy[0].geo) {
            setLoc(theMetros[0][x].geo_code);
          }
        }
    }
    else if(selectedPolicy[0].geolevel === "state") {
      for(var x = 0; x < theStates[0].length; x++) {
        if(theStates[0][x].DisplayName === selectedPolicy[0].geo) {
          setLoc(theStates[0][x].geo_code);
        }
      }
    }
    else {
      for(var x = 0; x < theCountries[0].length; x++) {
        if(theCountries[0][x].DisplayName === selectedPolicy[0].geo) {
          setLoc(0);
        }
      }
    }
  }
}

  return (
    <>
      <StyledDiv active={active}>
        {policies !== undefined && policies.length > 0 ?
          // Display types are text, graph, icon
          <Grid celled="internally" style={{ height: "100%" }}>
            <Grid.Row style={{ fontSize: "1rem", padding: "3% 0 0", lineHeight: "1.5em", height: "10%", fontWeight: "bold" }}>
              <Title>We have {policies.length > 1 ? policies.length + " policies recommendations" : policies.length + " policy recommendation"} </Title>
            </Grid.Row>
            <Grid.Row style={{ width: "100%", height: "90%", padding: "3%" }}>
            {/* Looping through policy items */}
            {policies.map((sp, index) => {
              const polLen = policies.length;

              // Reset the count if the selected number is outside the number of policies
              if (selected > polLen - 1) {
                nextPolicy();
              }

              if (selected === index) {
                return (
                  <>
                    <PolicyVisual>
                      {sp.display_type === "text" ?
                        <>
                          <Visual>
                            <LineGraph dataType={sp.target_factor} actualVal={sp.target_actual}
                              predictedVal={sp.target_predicted} predictedHighVal={sp.confidence_high}
                              predictedLowVal={sp.confidence_low} />
                          </Visual>
                          {polLen > 1 ? <Prev onClick={prevPolicy}><FontAwesomeIcon icon={faAngleLeft} style={{}} /></Prev>
                            : <DisPrev><FontAwesomeIcon icon={faAngleLeft} /></DisPrev>}
                          {polLen > 1 ? <Next onClick={nextPolicy}><FontAwesomeIcon icon={faAngleRight} /></Next>
                            : <DisNext><FontAwesomeIcon icon={faAngleRight} /></DisNext>}
                        </>
                        : sp.display_type === "graph" ?
                          <>
                            <Visual>
                              <BarGraph dataType={sp.target_factor} actualVal={sp.target_actual}
                                predictedVal={sp.target_predicted} feature={sp.dataPoints} title={sp.policy} />
                            </Visual>
                            {polLen > 1 ? <Prev onClick={prevPolicy}><FontAwesomeIcon icon={faAngleLeft} style={{}} /></Prev>
                              : <DisPrev><FontAwesomeIcon icon={faAngleLeft} /></DisPrev>}
                            {polLen > 1 ? <Next onClick={nextPolicy}><FontAwesomeIcon icon={faAngleRight} /></Next>
                              : <DisNext><FontAwesomeIcon icon={faAngleRight} /></DisNext>}
                            <BarGraphPopup />
                          </>
                          : sp.display_type === "icon" ?
                            <>
                              <Visual>
                                icon
                              </Visual>
                              {polLen > 1 ? <Prev onClick={prevPolicy}><FontAwesomeIcon icon={faAngleLeft} style={{}} /></Prev>
                                : <DisPrev><FontAwesomeIcon icon={faAngleLeft} /></DisPrev>}
                              {polLen > 1 ? <Next onClick={nextPolicy}><FontAwesomeIcon icon={faAngleRight} /></Next>
                                : <DisNext><FontAwesomeIcon icon={faAngleRight} /></DisNext>}
                            </>
                            : <div></div>}
                        {sp.response_type === "positive" ? 
                          <Descrip>
                            Between {" " +  sp.duration + ", " } there is {sp.confidence_level + " "} evidence that   
                            {" " + selGeo.DisplayName + " "} could have {sp.target_factor === "funding" ? " raised " : sp.target_factor === "founders" ? " " : " created " } {" "}
                            <ConfidencePopup hoverData={sp} name={("confidenceTool")}><b><u>{
                              format(sp.target_factor, sp.target_intervention, true)}{sp.target_factor !== "funding" && " more " + sp.target_factor}</u></b> {" "}
                            </ConfidencePopup>
                            {sp.target_factor === "funding" && " in additional funding "}
                            if policies like <PolicyDescriptionPopup hoverData={sp} name={("policydescrip")}><b><u>{sp.policy_full}</u></b> </PolicyDescriptionPopup>
                            were implemented.
                          </Descrip>
                        : 
                          <Descrip>
                            Between {" " +  sp.duration + ", " } there is {sp.confidence_level + " "} evidence that entrepreneurs in
                            {" " + selGeo.DisplayName + " "} would recover {" "}
                            <ConfidencePopup hoverData={sp} name={("confidenceTool")}><b><u>{
                              format(sp.target_factor, sp.target_intervention, true)}{sp.target_factor !== "funding" && " " + sp.target_factor}</u></b> {" "}
                            </ConfidencePopup>
                            {sp.target_factor === "funding" && " in funding "}
                            if policies like <PolicyDescriptionPopup hoverData={sp} name={("policydescrip")}><b><u>{sp.policy_full}</u></b> </PolicyDescriptionPopup>
                            were repealed.
                          </Descrip>
                        }
                        <PageCount>{sp.num+1}/{policies.length}</PageCount>
                    </PolicyVisual></>
                )
              }
            })
            }
            </Grid.Row>
          </Grid>
          :
          <Grid celled="internally">
            <Grid.Row style={{ fontSize: "clamp(11px, 1vw, 15px)", padding: "6%", lineHeight: "1.5em", height: "10%", whiteSpace: "pre-wrap" }}>
              { // Country Level policy display
                selGeo.level === "country" && sugPol !== undefined && sugPol[0] !== undefined ?
                  <><span>
                  {"We analyze the effects that public policies have on their region's entrepreneurial outcomes. \n\nFor an example of policy recommendations and their estimated effects, "} 
                  {"click the button below to be redirected to \n\n"} 
                  <StyledButton><Button onClick={() => GeographyRedirect(sugPol)}>{sugPol[0].geo}</Button></StyledButton>
                  {"\n\nTo submit additional policies for consideration, email "}
                  <a href="mailto:info@startout.org" style={{transition:"none"}}>info@startout.org</a>
                  </span></>
                  // State/Metros with no policies display this information on the panel
                : sugPol !== undefined && sugPol[0] !== undefined &&
                  <><span>
                    {"At this time, we have not identified any " + selGeo.level + " policies with a statistically significant effect on entrepreneurial outcomes for this area. "} 
                    {"\n\nClick the button below to be redirected to a policy recommendation for \n\n"}  
                    <StyledButton><Button onClick={() => GeographyRedirect(sugPol)}>{sugPol[0].geo}</Button></StyledButton>
                    {"\n\nTo submit additional policies for consideration, email "}
                    <a href="mailto:info@startout.org" style={{transition:"none"}}>info@startout.org</a>
                  </span></>
              }
            </Grid.Row>
          </Grid>
        }
      </StyledDiv>
    </>
  );
}

export default Policies;
