import React, { useRef, useEffect } from "react";
import useDimension from "../../hooks/useDimension";
import VisD3 from "./VisD3";
import VisOptions from "./VisOptions";
import FeatureSelector from "./Chart/FeatureSelector";
import YAxisSelector from "./Plot/YAxisSelector";
import XAxisSelector from "./Plot/XAxisSelector";
import { Grid, Image } from "semantic-ui-react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { visBackgroundColorState } from "recoil/selectors";
import { visWidthState, visHeightState, visState } from "recoil/atoms";
import InfoTip from "components/InfoTip";

const TheVis = () => {
  const backgroundColor = useRecoilValue(visBackgroundColorState);
  const visRef = useRef(null);
  const setVisWidth = useSetRecoilState(visWidthState);
  const setVisHeight = useSetRecoilState(visHeightState);
  const { height, width } = useDimension(visRef);
  const d3Ref = useRef(null);
  const vis = useRecoilValue(visState);

  useEffect(() => {
    setVisWidth(width);
    setVisHeight(height);
    return () => {};
  }, [width, height]);

  return (
    <>
      <Grid.Column
        className={"right-side"}
        mobile={16}
        computer={11}
        style={{
          background: backgroundColor,
          paddingTop: "2rem",
          overflow:"hidden",
        }}
      >
        <Grid textAlign="left" style={{ paddingLeft: "1vw" }}>
          <Grid.Column width={16}>
            <VisOptions />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column
            textAlign="left"
            width={8}
            style={{
              padding: "0 0 0 3%",
              minHeight: "5.45em"
            }}
          >
            
            {vis === "plot" ?
              <YAxisSelector d3Ref={d3Ref} />
            : vis === "chart" ? 
              <FeatureSelector />
            : <div></div>
            }
          </Grid.Column>
          <Grid.Column
            textAlign="left"
            width={8}
            style={{
              padding: "0 0 0 3%",
              minHeight: "3.75em",
            }}
          >
          </Grid.Column>
          <Grid.Column
            width={16}
            style={{ overflow: "visible" }}
          >

            <div className="main-vis-container" ref={visRef} style={{ marginRight: "0"}}>
              <VisD3 d3Ref={d3Ref} />
            </div>
            <XAxisSelector />
            
            <div
        style={{
          marginRight: "25%",
          marginLeft: "5%",
          padding: ".2em",
          paddingLeft: "1em",
          paddingRight: "1em",
          backgroundColor: "rgb(224, 225, 226)",
          borderRadius: "5px",
          opacity: vis === "map" || vis === "plot" ? 1 : 0,
          position: "relative",
          transition: "opacity 150ms linear",
          width:"40%",
          marginTop:"3%",
        }}
      >
        <Image fluid src={'https://storage.googleapis.com/peii-widget/startout-v2/key_black.png'} />

        <Grid
          style={{
            padding: 0,
            margin: 0,
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <InfoTip name="gap" nowrap={true}>
            <Grid.Column width={10} data-tip data-for="gapTip"></Grid.Column>
          </InfoTip>
          <InfoTip name="scale" nowrap={true}>
            <Grid.Column width={6} data-tip data-for="scaleTip"></Grid.Column>
          </InfoTip>
        </Grid>
      </div>

          </Grid.Column>
        </Grid>
        
      </Grid.Column>
    </>
  );
};


export default TheVis;
