import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import { Popup, Card } from "semantic-ui-react";
import { useRecoilValue } from "recoil";
import { selectedMetroState, selectedIndustrySector } from "recoil/selectors";
import { selectedIndustryDomState } from "recoil/atoms";

const IndustryPopup = () => {
  const metro = useRecoilValue(selectedMetroState);
  const industry = useRecoilValue(selectedIndustrySector);
  const svgRef = useRef(null);
  const selectedIndustryDom = useRecoilValue(selectedIndustryDomState);

  // update based on ui changes
  useEffect(() => {
    const svg = d3.select(svgRef.current);

    return () => {};
  }, [industry, metro]);
  return (
    <>
      {industry && selectedIndustryDom && (
        <Popup
          className="socos-popup"
          position="top right"
          basic
          context={selectedIndustryDom}
          open={true}
          style={{ padding: "0px" }}
          size="mini"
        >
          <Popup.Content className="socos-styles">
            <Card>
              <Card.Content>
                <div
                  style={{
                    fontSize: "18px",
                    lineHeight: "1.4em",
                    marginBottom: 0,
                  }}
                >
                  {industry.DisplayName}
                </div>
                &nbsp;&nbsp;(<i>{industry.sector}</i>)
                <br /><br />
                All companies: <b>{industry.all_count}</b>
              </Card.Content>
              <Card.Content>
                {metro.population_text} companies:{" "}
                <b>{industry.population_count}</b>
                <br />
                {metro.population_text === "All" && (
                  industry.likelihood_ratio > 1.5 ?
                    <>
                      <b>Overrepresented</b>
                      <br />
                    </>
                   : 
                  industry.likelihood_ratio < 0.75 ?
                    <>
                      <b>Underrepresented</b>
                      <br />
                    </> : <span></span>
                )}
                Industry Index (National Avg.):{" "}
                <b>{d3.format(".0f")(industry.index_score)}</b>
              </Card.Content>
            </Card>
          </Popup.Content>
        </Popup>
      )}
    </>
  );
};

export default IndustryPopup;
