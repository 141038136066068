import React from "react";
import { Button } from "semantic-ui-react";
import { useRecoilState, useRecoilValue } from "recoil";
import { viewState } from "recoil/atoms";
import { analyticsDataState } from "recoil/selectors";
import useWindowSize from "../../../hooks/useWindowSize";
import styled from "styled-components";

const StyledButton = styled.div`
  .button {
    width:content-fit;
    font-size:clamp(12px,1.1vw,16px);
    border solid 1px #CFCFCF;
  }

  .ui.button, .ui.active.button {
    width:content-fit;
    font-size:clamp(12px,1.1vw,16px);
    border solid 1px #CFCFCF;
    box-shadow:0px 0px 5px #a4a0a0;
  }

`;

const ViewSelector = () => {
  const [view, setView] = useRecoilState(viewState);
  const analyticsData = useRecoilValue(analyticsDataState);
  const windowSize = useWindowSize();

  const handleClick = (view) => {
    setView(view);
    // analytics.logEvent(
    //   `view_click_${{ view1: "totals", view2: "means" }[view]}`,
    //   analyticsData
    // );
  };

  return (
    <StyledButton>
      <Button
        color={view === "Mean" ? "teal" : ""}
        active={view === "Mean"}
        onClick={() => handleClick("Mean")}
      >
        Mean
      </Button>
      <Button
        color={view === "Total" ? "teal" : ""}
        active={view === "Total"}
        onClick={() => handleClick("Total")}
      >
        Total
      </Button>
    </StyledButton>
  );
};

export default ViewSelector;
