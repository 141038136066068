import React from "react";
import ReactTooltip from "react-tooltip";
import { useRecoilValue } from "recoil";
import tips from "res/infoTips";
import { populationState, viewState } from "recoil/atoms";
import styled from "styled-components";

const StyledReactTooltip = styled(ReactTooltip)`
  max-width: 250px;
  z-index: 999999;
  &.show {
    opacity: 0.96 !important;
  }
`;

const InfoTip = ({ as = null, name, children, id = "", ...rest }) => {
  const ElementType = as || "div";
  const populationText = useRecoilValue(populationState);
  const viewText = useRecoilValue(viewState);
  const {
    content = () => " ",
    place = "top",
    effect = "solid",
    type = "light",
  } = tips[name];
  const contentString = content({ pop: populationText, vws: viewText });

  return (
    <>
      {rest.nowrap ? (
        children
      ) : (
        <ElementType id={id} data-tip data-for={`${name}Tip`}>
          {children}
        </ElementType>
      )}
      <StyledReactTooltip
        className="extraClass"
        clickable={true}
        id={`${name}Tip`}
        place={place}
        effect={effect}
        type={type}
        border={true}
        {...rest}
      >
        {contentString}
      </StyledReactTooltip>
    </>
  );
};

export default InfoTip;
